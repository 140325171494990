<template>
  <div class="box">
    <app-header
      class="pcHeader"
      v-if="showFooter"
    ></app-header>
    <popup-menu
      ref="popupMenus"
      :title="$t('linkAccount')"
      :logoMobile="logoMobile"
    ></popup-menu>
    <mobile-header
      :popupMenu="PopupMenuRefs"
      :logoMobile="logoMobile"
      class="moHeader"
      v-if="showFooter"
    ></mobile-header>
    <div class="main">
      <router-view 
      v-if="isRouterAlive" 
      :logoPc="logoPc" 
      :encryptedPasswordSwitch='encryptedPasswordSwitch'
      ></router-view>
    </div>
    <div
      class="footer"
      v-if="showFooter"
    >
      <app-foot 
        v-if="!pickFooter"
        :footerHWA2="footerHWA2"
        :contactusVal="contactusVal"
      ></app-foot>
      <HwidFooter v-if="pickFooter" :footerHWA2="footerHWA2"></HwidFooter>
    </div>
  </div>
</template>

<script>
import AppHeader from './Header.vue'
import AppFoot from './Footer'
import HwidFooter from './HwidFooter'
import MobileHeader from './MobileHeader'
import PopupMenu from '@/components/popupMenu'
import { baseInfo } from '@/api'
export default {
  components: {
    AppHeader,
    AppFoot,
    HwidFooter,
    MobileHeader,
    PopupMenu
  },
  provide() {
    return {
      reload: this.reload,
      encryptedPasswordSwitch:this.encryptedPasswordSwitch
    }
  },
  data() {
    return {
      isRouterAlive: true,
      PopupMenuRefs: '',
      showFooter: true,
      pickFooter: true,
      logoPc: '',
      logoMobile: '',
      footerHWA2: this.$t('copyright'),
      contactusVal: 'javascript:void(0)',
      encryptedPasswordSwitch:'' // 登录密码加密开关
    }
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      if (to.name == 'privacyPolicy' || to.name == 'login') {
        this.showFooter = false
      } else {
        this.showFooter = true
      }
      if (to.name == 'account') {
        this.pickFooter = true
      } else {
        this.pickFooter = false
      }
      next();
    });
  },
  mounted() {
    this.PopupMenuRefs = this.$refs.popupMenus
    this.getBaseInfo()
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      location.reload()
      this.$nextTick(function() {
        this.isRouterAlive = true
      })
    },
    getBaseInfo() {
      const path = window.location.href
      baseInfo({
        lang: this.locale,
      }).then(res => {
        if (res) {
          this.footerHWA2 = res.copyright
          if (res.contactUs) {
            if(res.contactUs.indexOf('mailto:') > -1) {
              this.contactusVal = res.contactUs
            } else {
              this.contactusVal = 'mailto:' + res.contactUs
            }
          }
          this.logoPc = res.logoPc
          this.logoMobile = res.logoMobile
          this.encryptedPasswordSwitch = res.encryptedPasswordSwitch
        }
      })
    },
  }
}
</script>
<style scoped >
.template-main {
  height: 100%;
  text-align: center;
}
.page {
  background: #007bb64a;
  display: flex;
  width: 100%;
}
.box {
  height: 100%;
  background-color: #f5f5f5;
}
.main {
  background-color: #f5f5f5;
  /* position: relative; */
  /* height: 100%; */
  width: auto;
  max-width: 100%;
  background-size: cover;
  min-height: 830px;
}
.moHeader {
  display: none;
}
@media screen and (max-width: 767px) {
  .box {
    background-color: #fff;
  }
  .main {
    background-color: #fff;
    min-height: auto;
  }
  .pcHeader {
    display: none;
  }
  .moHeader {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
  }
}
</style>
