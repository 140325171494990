import { createI18n } from 'vue-i18n'
import locale from '@aurora/vue-locale'
import { CN } from './zh_CN' // 简体中文 (中国) 汉语
import { US } from './en_US' // English (United States) 英语

const messages = {
  'zh_CN': CN,
  'en_US': US,
}
export default (i18n) =>
  locale.initI18n({
    i18n,
    createI18n,
    messages: messages
  })
