import { US } from '@/i18n/en_US';
import { CN } from '@/i18n/zh_CN';

/**
 * 后台返回的异常提示处理
 * @param {string} code 状态码
 * @param {string} val 提示信息
 * @param {object} obj 后台返回对象
 */
export function processMessage(code, val, obj) {
  if (!val) return ''
  let message = val
  for (const k in obj) {
    if (k.indexOf('arg') !== -1) {
      let value = obj[k]
      // 您已经达到登陆失败次数的最大限制，请{0}秒后重试！
      if (code === 'HWIDRELA_000027') {
        value = parseInt(value) / 1000
      }
      message = message.replace(`{${k.replace('arg', '')}}`, value)
    }
  }
  if (code === 'HWIDLOGIN_000005') {
    message = message.replace('{0}', obj.lockedTime)
  }
  return message
}

// 协议弹窗
export function popu(type, language) {
  if (type === 1) {
    var policyFold = browserMode === 'mobile' ? '/accounts/mobile/policy/' : '/accounts/policy/'
    var policyUrl = policyFold + 'policy.jsp'
    if (language) {
      var policyV = 'v2'
      policyUrl = policyFold + 'policy_' + policyV + '_' + language + '.jsp'
    }
    if (browserMode === 'mobile') {
      window.location.href = policyUrl
    } else {
      window.open(policyUrl, '', 'width=830,height=630,resizable=no,status=no,toolbar:no,scrollbars=yes')
    }
    return
  }

  var fold = browserMode === 'mobile' ? 'mobile/agreement/' : 'registration/'
  var openurl = browserMode === 'mobile' ? fold + 'agreement_zh_CN.jsp' : fold + 'Registration.jsp'
  var greement = browserMode === 'mobile' ? 'agreement_' : 'Registration_'
  if (language) {
    var agreementV = 'v1'
    openurl = '/accounts/' + fold + greement + agreementV + '_' + language + '.jsp'
  }
  if (browserMode === 'mobile') {
    window.location.href = openurl
  } else {
    window.open(openurl, '', 'width=830,height=630,resizable=no,status=no,toolbar:no,scrollbars=yes')
  }
}
export function getCookie(name) {
  const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
  const arr = document.cookie.match(reg)
  if (!arr) {
    return 'zh_CN'
  }
  let lang = unescape(arr[2])
  if (!lang || lang === 'zh' || lang === 'cn' || lang === 'zh_CN') {
    lang = 'zh_CN'
  } else if (lang === 'en' || lang === 'en_US') {
    lang = 'en_US'
  }
  if (arr) {
    return lang
  } else {
    return null
  }
}

// 新增事件
export function addEventListener(node, event, listener) {
  if (node.addEventListener) {
    node.addEventListener(event, listener, false)
  } else {
    node.attachEvent('on' + event, listener)
  }
}

export function isAndroid() {
  return window.navigator.userAgent.toUpperCase().indexOf('ANDROID') !== -1
}

// 是否是IOS系统
export function isIphone() {
  return navigator.userAgent.match(/iPhone|iPad|iPod/i)
}

1 /**
2  * [通过参数名获取url中的参数值且进行解码操作]
3  * 示例URL:http://htmlJsTest/getrequest.html?uid=admin&rid=1&fid=2&name=小明
4  * @param  {[string]} queryName [参数名]
5  * @return {[string]}           [参数值]
6  */
export function getQueryValue(queryName,type = false) {
  let reg = new RegExp('(^|&)' + queryName + '=([^&]*)(&|$)')
  let params = window.location.hash.slice(window.location.hash.indexOf('?')+1)
  let r = params ? params.match(reg) : null
  if (r !== null && r !== undefined && !type) {
    return r[2]
  }else if(r !== null && r !== undefined && type){
    return r[2]
  }
  return ''
}
// [通过参数名获取url中的参数值但不进行解码操作]
export function getQueryVal(queryName) {
  let hash = ''
  if(queryName == 'ticket' && window.location.search.indexOf("ticket=") >= 0){
    hash = window.location.search
  }else{
    hash = window.location.hash
  }
  const params = hash.split('?')[1]
  if(params == undefined) return ''
  const vars = params.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == queryName) {
      return pair[1]
    }
  }
  return ''
}
// 获取URL参数， 按照后端逻辑，获取一次解码后的值
export function getQueryParam4backend(queryName) {
  try {
    let reg = new RegExp('(^|&|[?])' + queryName + '=([^&?#]*)(&|$|[?#])')
    let params = window.location.href.slice(window.location.href.indexOf('?')+1)
    let r = params ? params.match(reg) : null
    if (r !== null && r !== undefined) {
      return decodeURIComponent(r[2])
    }
  } catch(error) {
  }
  return ''
}
// 获取支持的国际化
export function getLanguageList() {
  const data = []
  const fileList = require.context('@/i18n', false, /.js$/)
  const path = require('path')
  fileList.keys().forEach((item) => {
    if (item != './index.js') {
      let fileName = path.basename(item, '.js')
      let localFile = require('../i18n/' + fileName + '.js')
      let lastTwo = fileName.substring(fileName.length - 2)
      let curLang = localFile[lastTwo][lastTwo]
      data.push({
        [fileName]: curLang
      })
    }
  })
  return data
}

export const browserMode = isAndroid() || isIphone() ? 'mobile' : 'pc'

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) || window.innerWidth <= 500
}

// 返回登陆页
export function callbackLoginMethod(loginType) {
  switch (loginType) {
    case '0':
      return '/account';
    case '1':
      return '/uniportalAccount';
    case '2':
      return '/w3Account';
    default:
      return '/uniportalAccount';
  }
}

import { checkRange } from '@/api';
export function checkUserRange(callbackUrl, _this, fromUrl) {
  checkRange({}).then(res => {
    if (!res) {
      const error = { errorCode: 'CAS-00-00003' }
      checkUserRangeCatch(error, _this, fromUrl);
      return;
    }
    location.href = callbackUrl;
  })
}

export function checkUserRangeCatch(error, _this, fromUrl) {
  if (error.errorCode === 'CAS-00-00003') {
    const path = _this.$route.path;
    if (path === '/certification') {
      sessionStorage.setItem('cas-error-path', fromUrl);
    } else {
      sessionStorage.setItem('cas-error-path', path);
    }
    const query = location.href.split('?')[1];
    _this.$router.push(`/error?${query}`);
  }
}

function urlSplitObj(url) {
  let obj = {};
  let str = url.split('&');
  for (let i = 0; i < str.length; i++) {
    let val = str[i].split('=');
    obj[val[0]] = val[1];
  }
  return obj;
}

const lang = getCookie('lang') || 'zh_CN';
const messages = {
  'en_US': US,
  'zh_CN': CN
}

export function setErrorMessage(errorCode, _this) {
  const errorObj = {
    'CAS-02-00006': messages[lang].hwAccountInvalid,
    'CAS-02-00028': messages[lang].mobileNumberFormatTip,
    'CAS-02-00038': messages[lang].uidHasExpired,
    'CAS-02-00007': messages[lang].moreAccountTips,
    'CAS-03-00008': messages[lang].serviceEmptyTips,
    'CAS-06-00025': messages[lang].moreAccountErrorTips,
    'CAS-07-00001': messages[lang].verifyCodeError,
    'CAS-07-00010': messages[lang].timeOut,
    'CAS-07-00026': messages[lang].mobileNumberFormatTip,
    'CAS-02-00040': messages[lang].phoneEmailRejectLogin,
    'CAS-02-00041': messages[lang].errorCAS0200041,
    'CAS-02-00043': messages[lang].errorCAS0200043,
    'CAS-07-00009': messages[lang]['CAS-07-00009'],
    'CAS-07-00031': messages[lang]['CAS-07-00031'],
    'CAS-07-00032': messages[lang]['CAS-07-00032'],
  };
  for (const [key, value] of Object.entries(errorObj)) {
    if (key === errorCode) {
      return value;
    }
  }
}
 
export function checkPhone(phone) {
  return /^\d+$/.test(phone);
}

// 解码地址
export function getDecodeUrlParams(queryName) {
  let reg = new RegExp('(^|&)' + queryName + '=([^&]*)(&|$)')
  let params = window.location.hash.slice(window.location.hash.indexOf('?')+1)
  let r = params ? params.match(reg) : null;
  if (r) {
    return decodeUrl(r[2]);
  }
  return '';
}
function decodeUrl(targetUrl) {
  if (decodeURIComponent(targetUrl) === targetUrl) {
    return targetUrl;
  } else {
    targetUrl = decodeURIComponent(targetUrl);
    return decodeUrl(targetUrl);
  }
}

// 拼接参数
export function getUrlStr(params) {
  if (!params) {
    return '';
  }
  let result = '';
  params && Object.keys(params).forEach((item) => {
    if (params[item]) {
      result += `${item}=${params[item]}&`
    } else {
      result += `${item}=&`
    }
  })
  return result.slice(0, -1)
}

// 获取fp数据
export function getFpData() {
  let fp;
  if (window['idss_fp'] && window['idss_fp'].fp) {
    fp = window['idss_fp'].fp;
  }
  return fp ? fp : {};
}
