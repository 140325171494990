<template>
  <!--页尾-->
  <div class="pub-footer">
    <div class="pub-footer-left">
      <span v-text="footerHW"></span>
      <span>
        <p
          class="footer-a2"
          v-html="footerHWA2"
        ></p>
      </span>
    </div>
    <div class="pub-footer-right">
      <span v-if="flag">
        <a
          href="javascript:void(0)"
          class="footer-a2"
          @click="footerContactUsFn"
          v-text="footerContactUs"
        ></a>
        <span class="icon-line">|</span>
      </span>
      <span>
        <a
          href="javascript:void(0)"
          @click="forwardPrivacy()"
          target="_self"
          class="footer-a2"
          v-text="footerPrivacyPolicy"
        ></a>
      </span>
    </div>

    <!-- 联系我们 -->
    <aui-dialog-box
      :visible="boxVisibility"
      @update:visible="boxVisibility = $event"
      dialog-class="contactusdialogBox"
      :title="$t('contactUs')"
      width="25%"
      top="30vh"
      append-to-body
    >
      <div>
        <p class="us-dialog-title">
          {{ $t('serviceConsulting') }}
        </p>
        <a :href="contactusVal" class="us-dialog-link">
          {{footerContactUsUrlFn(contactusVal)}}
        </a>
      </div>
    </aui-dialog-box>
  </div>
</template>
<script>
import { detailAgreementUrl } from '@/assets/js'
import { getQueryValue, getQueryVal, isMobile } from '@/utils/utils'
import { DialogBox } from '@aurora/vue'
export default {
  components: {
    AuiDialogBox: DialogBox
  },
  data() {
    return {
      footerContactUs: this.$t('contactUs'),
      footerPrivacyPolicy: this.$t('ppArgee'),
      footerHW: '',
      footerHWPhone: this.$t('chtlarr'),
      msg: 'Welcome to Your Vue.js App',
      flag: true,
      locale: '',
      boxVisibility: false,
    }
  },
  props: {
    footerHWA2: {
      type: String,
      default: ''
    },
    contactusVal: {
      type: String,
      default: 'javascript:void(0)'
    },
  },
  created() {
    this.locale = this.$i18n.locale
  },
  computed: {
    footerContactUsUrlFn() {
      return (val) => {
        if (val) {
          return val.split(':')[1];
        }
        return '';
      }
    }
  },
  methods: {
    forwardLegaly() {
      return null
    },
    forwardLegal() {
      return null
    },
    forwardPrivacy() {
      let pageUrl = location.origin + location.pathname + '#/privacyPolicy?service=' +
        getQueryValue('service', true) + '&tenantId=' + getQueryVal('tenantId');
      if (isMobile()) {
        window.open(pageUrl, '_self')
      } else {
        window.open(pageUrl)
      }
    },
    footerContactUsFn() {
      this.boxVisibility = true;
    },  
  }
}
</script>
<style lang="scss">
  p {
    padding: 0;
    margin: 0;
  }
</style>
<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  .pub-footer {
    background: #3e3f4a;
    height: 36px;
    line-height: 36px;
    font-size: 12px;
    color: #afb1b6;
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  .pub-footer-left {
    float: left;
    font-family: Helvetica;
    margin-left: 100px;
    color: #afb1b6;
    line-height: 36px;
  }
  .pub-footer-left span a {
    color: #afb2b8;
    text-decoration: none;
    margin-left: 18px;
  }
  .pub-footer-right .icon-line {
    margin: 0 10px;
  }
  .pub-footer-right span a {
    color: #afb2b8;
    text-decoration: none;
  }
  .pub-footer-right {
    float: right;
    margin-right: 10%;
  }
}
@media screen and (max-width: 767px) {
  // 移动端
  .pub-footer {
    display: none;
  }
}
.contactusdialogBox {
  :deep(.aui-dialog-box__title) {
    font-size: 18px;
    font-weight: 800;
  }
  .us-dialog-title {
    margin-bottom: 12px;
    font-size: 16px;
    color: #666;
  }
  .us-dialog-link{
    color: #666;
    text-decoration: none;
  }
  :deep(.aui-dialog-box__body) {
    margin-bottom: 0;
    padding: 20px 20px 60px;
  }
}

</style>
