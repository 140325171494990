<template>
  <aui-home></aui-home>
</template>

<script>
import AuiHome from './views/layout/Home'

export default {
  name: 'App',
  components: {
    AuiHome
  },
  watch: {
    '$route.meta': {
      deep: true,
      handler: 'setTitle'
    }
  },
  methods: {
    setTitle() {
      this.$nextTick(() => {
        const { title } = this.$route.meta || {}
        if (title) {
          document.title = title
        }
      })
    }
  },
  mounted() {
    this.setTitle()
  }
}
</script>
