export const CN = {
  top: '返回目录',
  accountisempty: '帐号不能为空。',
  accountlength: '帐号长度不能大于50位',
  systemacccount: '帐号不能以pub_、eta_、igl_开头',
  staffaccount: '帐号不能是1-3位字母+数字的形式',
  acccountprefix: '帐号应以数字或字母开头',
  accountcharachter: '只能包含字母、数字或 - _ .',
  bindingTitle: '绑定Uniportal帐号',
  bindinginfo: '尊敬的华为用户，这是您首次登录华为公司IT应用，您需要绑定已有的Uniportal帐号基本信息。',
  bindingCloudInfo:
    '尊敬的华为用户，这是您首次登录华为公司IT应用，系统检测到您的华为帐号在华为云登录并且绑定过Uniportal帐号，您需要绑定已有的uniportal帐号基本信息。',
  bindinginfo2: '您要使用手机号还是邮箱地址，绑定您的Uniportal帐号？',
  bindinginfo3: '您要使用手机号还是邮箱地址，创建您的Uniportal帐号？',
  bindinginfo4: '如果您使用手机号，您仍需要验证邮箱地址以便验证您的身份。',
  usePhone: '使用手机号',
  useEmail: '使用邮箱地址',
  emailAddress: '邮箱地址',
  forgetpwd: '忘记密码？ 请先',
  restPassword: '找回密码',
  previous: '上一步',
  next: '下一步',
  bindingAccount: '绑定帐号',
  createAccount: '创建Uniportal帐号',
  createAccountInfo:
    '尊敬的华为用户，这是您首次登录华为公司IT应用，您需要创建新Uniportal帐号并同意相关协议声明以完成登录。',
  account: '帐号',
  lastName: '姓氏',
  firstName: '名字',
  email: '邮箱',
  smsVerification: '短信验证码',
  getCode: '获取验证码',
  agreement:
    '<span>我已阅读并同意</span><a href="javascript:popu(0);"> ' +
    '华为公司用户注册协议</a><span> 和 </span><a href="javascript:popu(1);"> 隐私声明</a>',
  moreMobileUserTip: '当前手机号已绑定多个Uniportal帐号，请选择本次要登录的帐号。',
  moreEmailUserTip: '当前邮箱已绑定多个Uniportal帐号，请选择本次要登录的帐号。',
  createAndLink: '创建并绑定帐号',
  emailVerification: '邮件验证码',
  emailEmptyTip: '邮箱不能为空',
  emailFormatTip: '邮箱格式错误',
  passwordEmptyTip: '密码不能为空',
  tips: '提示',
  mobileNumber: '手机号',
  mobileNumberEmptyTip: '手机号码不能为空',
  mobileNumberFormatTip: '手机号码格式错误',
  countryCode: '国家码不能为空',
  codeImage: '图形验证码',
  codeImageEmptyTip: '图形验证码不能为空',
  emailCodeEmptyTip: '邮件验证码不能为空',
  smsCodeEmptyTip: '短信验证码不能为空',
  submit: '提交',
  skip: '跳过',
  resend: '重新获取',
  cancel: '取消',
  ok: '确定',
  save: '保存',
  accountTip1: '帐号名应符合以下规则：',
  accountTip2: '帐号名应以数字或字母开头。',
  accountTip3: '仅能由如下字符构成：字母，数字，下划线，“.”，“-”。',
  accountTip4: '正确的帐号名格式如：<b>Jack_Wang</b>',
  lastNameEmptyTip: '姓氏不能为空',
  firstNameEmptyTip: '名字不能为空',
  lastNameCannotLength: '姓氏拼音的长度不能大于50位',
  firstNameCannotLength: '名字拼音的长度不能大于50位',
  accountEmptyTip: '帐号不能为空',
  lastnameCharater: '姓氏拼音不能包含字符：',
  firstnameCharater: '名字拼音不能包含字符：',
  sendCodeTip1: '验证码已发送到{n}',
  sendCodeTip2: '{n}s后自动关闭',
  parTip: '尊敬的华为用户，您需要通过手机号验证您的身份。经过验证的手机号可以用于登录或重置密码、修改密码。',
  parTip2: '尊敬的华为用户，您需要通过邮箱地址验证您的身份。经过验证的邮箱地址可以用于登录或重置密码、修改密码。',
  acls: '创建且绑定帐号成功',
  linkedSucc: '绑定帐号成功',
  mobileLengthTip: '手机号码长度不能小于6且不能大于30',
  nametszf: '您输入的姓名不能含有特殊字符和数字',
  heesepi: '您使用的是公司员工帐号或者是公司合作方员工帐号,请到W3平台修改您的个人信息',
  back: '返回',
  useCenter: '帐号中心',
  setNickName: '点击设置昵称',
  nickname: '昵称',
  nicknameEmpty: '昵称不能为空',
  ubi: 'Uniportal帐号基本信息',
  accountName: '帐号名',
  name: '姓名',
  nosetname: '未添加姓名',
  nosetpwd: '未设置密码',
  nosetphone: '未绑定手机号',
  nosetemail: '未绑定邮箱',
  verified: '已验证',
  notVerified: '未验证',
  updatePwd: '修改密码',
  linked: '已绑定',
  setpwd: '设置密码',
  linkAccount: '绑定帐号',
  unlink: '解绑帐号',
  chtvyi: '为确认是您本人操作，请选择验证方式验证您的身份',
  SMSVerification: '通过手机验证',
  SMSVerification2: '通过短信验证',
  EmailVerification: '通过邮箱验证',
  mobileNumberVerification: '手机号身份验证',
  emailVer: '邮箱身份验证',
  verificationSuccess: '验证成功',
  changedSuccess: '修改成功',
  unlinkeduccess: '解绑成功',
  changeName: '修改姓名',
  addName: '添加姓名',
  changeMobile: '修改手机',
  linkMobileNumber: '绑定手机',
  changeEmailAddress: '修改邮箱',
  linkEmail: '绑定邮箱',
  failed: '操作失败',
  success: '操作成功',
  uftay: '您确认解除第三方帐号和Uniportal帐号的关联吗？解除后您仍可以使用Uniportal帐号登录。您确认解绑？',
  password: '密码',
  oldPassword: '旧密码',
  newPassword: '新密码',
  confirmPassword: '确认密码',
  iknow: '我知道了',
  changedFailed: '修改失败',
  oldPasswordEmpty: '原密码不能为空',
  newPasswordEmpty: '新密码不能为空',
  npmcc: '密码长度不能小于8位',
  npcec: '密码长度不能大于60位',
  npcsoewas: '密码中不能含空格',
  npccyui: '密码中不能含有帐号',
  npmcldsc: '新密码必须包含字母、数字和特殊字符',
  cpcbe: '确认密码不能为空',
  cpmbtsanp: '两次输入密码必须保持一致',
  verification: '身份验证',
  backTip: '页面将在<span style="color: #007DFF;">{n}</span>秒后返回',
  backLogin: '返回登录',
  tuhnl: '未登录或登录己过期!',
  weChat: '微信',
  linkedin: '领英',
  useUserids: '您的帐号已被其他人占用，请重新填写。',
  passwordTip1: '密码长度不少于八位数字。',
  passwordTip2: '密码中不能包含帐号。',
  passwordTip3: '密码必须包含字母、数字和特殊字符。',
  contactUs: '联系我们',
  help: '帮助',
  chtlarr: '版权所有 © 华为技术有限公司 1998-2020。保留一切权利。   粤A2-20044005号',
  ppArgee: '隐私声明',
  loginOut: '退出',
  uAccountPassword: 'Uniportal帐号密码',
  selectAccount: '选择帐号',
  cypmg: '非常抱歉，W3帐号修改密码、修改手机号请移步内网ME服务',
  lemn: '该帐号没有绑定邮箱和手机号，您无法修改密码',
  lemn2: '该帐号没有绑定邮箱和手机号',
  mobileNumberRegister: '手机号已注册',
  emailRegister: '邮箱已注册',
  agreementTip: '请勾选“我已阅读并同意华为公司用户注册协议和隐私声明”。',
  informTip: '尊敬的华为用户，这是您首次登录华为公司IT应用，需要您完善以下用户信息。',
  compUserInfor: '完善用户信息',
  create: '提交',
  createUserSuccess: '完善用户信息成功',
  w3Account: '华为内部员工W3帐号',
  w3AccountLogin: 'W3帐号登录',
  login: '登录',
  hwAccountLogin: '华为帐号登录',
  accountIsEmpty: '帐号为空',
  accountTip: '帐号的长度不能大于100个字节,请重新填写',
  pwdIsEmpty: '密码为空',
  pwdTip: '密码长度必须在8到60个字符之间',
  accountErrpr: '帐号或密码错误，你还可以尝试',
  times: '次',
  errorTimes: '由于帐号或密码错误次数太多，帐号锁定',
  Seconds: '秒',
  otherLogin: '其他登录方式',
  directions: '我们为您提供华为帐号服务，在登录过程中会使用到您的帐号和网络信息提升登录体验。',
  underMore: '了解更多 >',
  directions1: '抱歉，华为帐号组件加载异常导致页面无法正常显示，您可以稍后刷新重试，也可以点击',
  directions2: '进入原Uniportal登录认证页面。',
  links: '链接',
  legalNotices: '法律声明',
  hwPrivacy: '关于华为帐号与隐私的声明',
  loginWithHuaweiId: '使用华为帐号登录',
  pravacyText1:
    '登录华为帐号，可访问并使用华为的应用/服务、华为网站，或其他允许华为帐号登录的非华为服务，' +
    '为提升登录体验，我们会对同一运营公司或签署过数据共享和保密协议的关联公司帐号用户，提供统一的登录方式。',
  pravacyText2:
    '您登录时，我们会根据您的IP地址，路由选择不同的运营公司为您提供服务。若路由到中国大陆接入，由华为软件技术有限公司运营的华为帐号为您提供服务（详见',
  pravacyText3: '）；若路由不在中国大陆，则华为服务（香港）有限公司运营的华为帐号为您提供服务（详见',
  pravacyText4:
    '若您是华为帐号用户，将由华为帐号为您提供登录服务，如不是，我们为将您输入的用户名和密码，传输给为您提供帐号登录的业务进行授权。华为帐号不会保存您输入的用户名和密码。',
  forgetPwd: '忘记密码',
  modifyPwd: '修改密码',
  appNotReg: '应用未注册',
  loggingOut: '正在登出...',
  loginLoading: '正在登录...',
  copyright: '版权所有 © 华为技术有限公司 1998-2022。保留一切权利',
  useCenter: '帐号中心',
  changePassword: '修改密码',
  login: '登录',
  ppArgee: '隐私声明',
  CN: '简体中文 (中国)',
  'CAS-03-00001': '找不到授予凭据。',
  'CAS-03-00002': '授予凭据已过期。',
  'CAS-03-00003': '找不到服务凭据。',
  'CAS-03-00004': '服务凭据已兑换。',
  'CAS-03-00006': '应用不存在。',
  'CAS-03-00007': '应用无效。',
  'CAS-01-00004': '访问非法。',
  'CAS-00-00001': '系统内部错误。',
  'CAS-07-00010': '60s内不能重复发送验证码。',
  'CAS-07-00009': '您发送短信验证码的次数过于频繁，请24小时后重试！',
  'CAS-02-00041': '邮箱或电话号码已禁止使用',
  'CAS-02-00043': '国家码已禁止使用',
  'CAS-02-00044': '手机号码格式错误。',
  'CAS-07-00011': 'mfa登录信息过期超过10分钟。',
  'CAS-07-00012': '验证码数量超过最大限制。',
  'CAS-07-00014': '帐号注册区域为空。',
  'CAS-07-00015': '邮件验证码为空。',
  'CAS-08-00006': '发送mfa消息失败。',
  'CAS-07-00031': '您发送短信验证码的次数过于频繁，请1小时后重试！',
  'CAS-07-00032': '您发送短信验证码的次数过于频繁，请5分钟后重试！',
  'CAS-07-00033': '您发送邮件验证码的次数过于频繁，请5分钟后重试！',
  'CAS-07-00034': '您发送邮件验证码的次数过于频繁，请1小时后重试！',
  'CAS-07-00035': '您发送邮件验证码的次数过于频繁，请24小时后重试！',
  loading: '加载中...',
  warmTips: '温馨提醒：仅支持华为内部员工W3帐号登录，外部用户请切换至',
  hwAccount: '华为帐号',
  pwdAccountTips: '请填写需要修改密码的帐号名/邮箱/手机号/W3帐号',
  pwdAccountForgetTips: '忘记密码？您可以通过帐号名/邮箱/手机号/W3帐号找回',
  pwdW3Account: '请输入W3帐号',
  pleaseEnterPassword: '请输入密码',
  backLoginTips: '返回登录页',
  pwdCodeTips: '为确认是您本人操作，请选择验证方式完成身份验证',
  pwdCodePhoneTips: '验证码将发送到手机号: ',
  pwdCodeEmailTips: '验证码将发送到邮箱：',
  pwdCodeChangePhone: '选择手机验证方式',
  pwdCodeChangeEmail: '选择邮箱验证方式',
  confirmNewPassword: '确认新密码',
  resetHWPassword: '修改域密码',
  pwdSuccessTips:
    '点击修改域密码按钮能同时修改您的域密码，修改成功后，如果使用新密码无法登录Windows，请尝试使用您的旧密码登录。',
  warmTips: '温馨提示',
  endTime:
    '您的密码将在 <span style="color: #F66F6A;">{time}</span> 天后过期，请及时修改密码，以免影响您的帐号正常使用',
  modifyNow: '立即修改',
  doNotmodifyNow: '暂不修改',
  expirationTips1: '您的密码已经过期，为了保证您的帐号安全，请立即修改密码。',
  expirationTips2: '如果不立即修改密码，您将无法正常访问网站',
  warningTips1: '您的W3帐号既没有手机号也没有邮箱，系统不支持重置、修改密码。您可以求助同事帮忙找回密码',
  warningTips2: '帮同事找回密码链接：',
  updateSuccess: '重置成功',
  againCode: '重新获取{time}s',
  hwAccountSuccessTips: '您的域密码修改成功',
  hwAccountSuccessTime: '页面将在 <span style="color: #238FFF;">{time}</span> 秒后返回登录！',
  hwAccountErrorTips: '系统异常，您的域密码修改失败',
  hwAccountInvalid: '您的手机号码尚未注册或未开启手机号登录，请点击立即注册。',
  accountNotExist: '此帐号不存在',
  passwordRules1: '为保障您的帐号安全，请按照如下规则重新设定密码：',
  passwordRules2: '1、密码长度至少8个字符，但不能大于60个字符。',
  passwordRules3: '2、密码必须包含字母，数字和特殊字符。',
  passwordRules4: '3、密码不能包含您的帐号名，邮箱地址，手机号。',
  passwordRules5: '4、不能使用最近2次用过的密码。',
  oldPasswordError: '旧密码错误',
  newPasswordError: '新密码设置不符合要求(新设密码不能与近期历史密码相同)',
  verifyCodeError: '验证码错误或者已过期',
  timeOut: '60s内不能重复发送验证码',
  longTime: '页面停留时间过长，请重新修改或重置密码',
  resetPassword: '重置密码',
  newPwdErrorTips: '新密码不能包含电子邮件地址、电话号码或帐号。',
  systemError: '系统内部错误，请稍后重试',
  serviceNotFound: '服务未注册',
  checkUserInfo: '需要验证您的身份',
  checkUserWarnTips: '您当前操作存在风险，为了帐号安全，请输入验证码。',
  checkUserSecond: '您访问的应用开启了二次认证，为了帐号安全，请输入验证码。',
  sendCodeTitle: '验证码将发送到：',
  morePhoneTips: '您的帐号绑定了多个手机号，请选择接收验证短信的手机号。',
  moreEmailTips: '您的帐号绑定了多个邮箱，请选择接收验证邮件的邮箱。',
  cerCodeErrorTips: '验证码错误或者已过期',
  certificationTitle: '认证',
  loginRefused: '暂不允许登录',
  sendSmsTips1: '短信验证码已发送到',
  sendSmsTips2: '，10分钟内有效！',
  sendEmailTips1: '邮箱验证码已发送到',
  uniportalAccountLogin: 'Uniportal帐号登录',
  w3AccountTips: 'W3帐号',
  registration: '立即注册',
  helpTips: '点击获取更多帮助？',
  passwordEnd: '密码已过期',
  passwordExpiring: '密码即将过期',
  setAccountPlaceholder: '帐号名/邮箱/手机号/W3帐号',
  accountPleaseSelect: '请选择',
  resetPwMultiAccountPhone: '您输入的手机号注册了多个帐号，请选择您要找回密码的帐号。',
  resetPwMultiAccountEmail: '您输入的邮箱注册了多个帐号，请选择您要找回密码的帐号。',
  ressetPwMultiAccount: '请选择你要修改密码的账号',
  closeButton: '关闭',
  uniportalAccount: '原Uniportal帐号',
  uniportalGives: 'Uniportal帐号是在华为官网注册的帐号，可访问华为官网华为运营商技术支持服务。',
  UniportalA: '找回原Uniportal帐号密码',
  rpw: '找回密码',
  hwGives:
    '华为帐号可访问所有华为服务，是您使用华为手机、手表等设备，以及华为云空间、华为商城、应用市场、开发者联盟等服务时的帐号。',
  hwA: '找回华为帐号密码',
  restrictedAccess: '访问受限',
  permissionError: '很抱歉！您没有权限访问当前页面。',
  changeAccount: '切换账号',
  changeAccountTips: '想继续访问，请点击此处',
  pleaseSelect: '请选择',
  accountLoginBtn: '帐号密码登录',
  smsLoginBtn: '短信验证码登录',
  moreAccountErrorTips: '该手机号码已关联多个帐号，不能通过短信登录系统。请使用其他方式登录系统。',
  moreAccountTips: '您的手机或邮箱已注册多个帐号，请选择帐号',
  uidHasExpired: 'UID已过期',
  serviceEmptyTips: 'service参数不能为空',
  pwdHasExpiredTips: '您的密码刚初始化或者已经过期，为了保证您的帐号安全，请修改密码。如果不立即修改密码，您将无法正常访问网站。谢谢。',
  pwdwillExpiredTips: '您的密码将在',
  pwdwillExpiredDayTips: '天后过期，请及时修改!',
  phoneEmailRejectLogin: '请勿使用W3绑定的手机或邮箱登录。',
  englishVersion: 'English',
  appHelp: '帮助',
  loginIssues: '登录时遇到问题了?',
  forgettingPassword: '我忘记密码了',
  accountLogin: '帐号登录',
  errorUserAndPsw: "错误的用户名或密码，再输错",
  lockFiveMinutes: "次将锁定。",
  forgetPsw: "忘记密码?",
  resetNewPsw: "重设一个新密码",
  beyondFailTimes: "您登录失败的次数已超出限制，请",
  retry: "秒后重试！",
  moreEnterPasswords: "频繁输入密码很麻烦?",
  passwordfreeLogin: "我要开启（关闭）免密登录",
  pleaseVisit: "请访问",
  myAccount: "我的帐号",
  noW3Acount: "我没有W3帐号",
  moreAccount: "更多帐号密码问题",
  resetYourPassword:
    "然后在输入框输入您的帐号并提交，您将收到一封激活邮件，点击邮件中的链接即可重置您的密码。",
  huaweiAccountRules:
    "华为员工入职时会分配一个“姓名首字母+工号”的W3帐号。非正式雇员请联系华为接口人帮忙填写",
  eflow: "电子流",
  applyW3Account: "申请W3帐号，审批完成即可完成W3帐号开户。",
  verificationCode: "收不到验证码？",
  codeRightTip: "验证码已发送，可能存在一定延时，请耐心等待，或重新获取",
  errorCAS0200041: '邮箱或电话号码已禁止使用',
  errorCAS0200043: '国家码已禁止使用',
  smsSendTo: '短信验证码已发送至',
  emailSendTo: '邮件验证码已发送至',
  tenMinutes: ', 10分钟内有效',
  freeLogin: '如何开通免密登录？',
  freeLoginFailed: '免密登录失败',
  serviceConsulting: '服务咨询：',
  error403Title: '403',
  error403Content: '您所请求的资源无法访问',
}
