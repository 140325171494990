import instance from './request'

const baseUrl = '' // 老系统路径

export const apis = {
  w3login: '/employee/login', // w3登录
  imgcodeUri: '/randomcode.jpg', // 验证码
  changeLang: '/hwid/common/change-lang',
  privacyDetail: '/privacy', // 隐私详情
  checklogin: '/checkLogin', // checkLogin
  w3Check: '/v1/pwd/verifyUser', // ogin/page/tenant
  modifyPassword: '/v1/pwd/modifyPassword', // 修改密码
  resetPassword: '/v1/pwd/resetPassword', // 重置密码
  sendEmailCode: '/captcha/send-email-code', // 发送email验证码
  checkEmailCode: '/captcha/check-email-code', // 校验email验证码
  sendSmsCode: '/captcha/send-sms-code', // 发送手机验证码
  checkSmsCode: '/captcha/validateSmsCode', // 校验手机验证码
  checkFirstCaptcha: '/captcha/firstVerify', // 滑块第一次校验
  checkSecondCaptcha: '/captcha/secondVerify', // 滑块第二次校验
  resetDomainPassword: '/v1/pwd/syncDomainPassword', // 滑块第二次校验
  captchaParams: '/captcha/captchaParams', // 滑块参数
  callback: '/third/hwid/callback',
  idmapping: '/third/idmapping',
  logout: '/logout',
  logoutInfo: '/get-logout-info',
  accountInfo: '/mfa/account-info', // 获取用户信息
  secondSendVerifyCode: '/mfa/send-verify-code', // 二次认证验证码
  validateVerifyCode: '/mfa/validate-verify-code', // 校验二次认证验证码
  uniportalLogin: '/customer/login', // uniportal登录
  setUniportalLink: '/login/setting/', // uniportal相关链接
  hwidConfig: '/login/page/config', // hwid页面是否可显示
  baseInfo: '/login/page/tenant', // 获取版权信息等
  privacyInfo: '/tenant/privacy',
  hwidFwUrl: '/hwid/login/forgot',
  smsLoginSmsSend: '/sms-send', // 短信登录发送验证码
  smsLogin: '/sms-login', // 短信登录校验验证码
  countryCodeList: '/country-code-list', // 国别码
  killCookie: '/kill-cookie', // 清除tgc cookie
  meServerUrl: '/v1/pwd/getPasswordUrl',
  getSpesInfo: '/spes/get-spes-info',
  xGateGetSpesInfo: 'https://localhost:18080/getSpesInfo',  //  本地XGATE获取信息
  spesLogin: '/spes/spes-login', // 免密登录
  checkRange: '/checkRange', // 校验用户范围
}

Object.keys(apis).map((key) => {
  apis[key] = baseUrl + apis[key]
})

export function getimgCodeUrl() {
  return Promise.resolve(apis.imgcodeUri + '?' + new Date().getTime())
}

export function getHwidPage(data) {
  return axiosFetch(`${apis.hwidConfig}`, 'get', data)
}
export function baseInfo(data) {
  return axiosFetch(`${apis.baseInfo}`, 'get', data)
}
export function privacyInfo(data) {
  return axiosFetch(`${apis.privacyInfo}`, 'get', data)
}
export function hwidFwUrl(data) {
  return axiosFetch(`${apis.hwidFwUrl}`, 'get', data)
}
export function w3login(data) {
  return axiosFetch(`${apis.w3login}`, 'post', data)
}
export function changeLang(data) {
  return axiosFetch(`${apis.changeLang}`, 'post', data)
}
export function checklogin(data) {
  return axiosFetch(`${apis.checklogin}`, 'post', data)
}
export function w3Check(data) {
  return axiosFetch(`${apis.w3Check}`, 'post', data)
}
export function modifyPassword(data) {
  return axiosFetch(`${apis.modifyPassword}`, 'post', data)
}
export function resetPassword(data) {
  return axiosFetch(`${apis.resetPassword}`, 'post', data)
}
export function sendEmailCode(data) {
  return axiosFetch(`${apis.sendEmailCode}`, 'post', data)
}
export function checkEmailCode(data) {
  return axiosFetch(`${apis.checkEmailCode}`, 'post', data)
}
export function sendSmsCode(data) {
  return axiosFetch(`${apis.sendSmsCode}`, 'post', data)
}
export function checkSmsCode(data) {
  return axiosFetch(`${apis.checkSmsCode}`, 'post', data)
}
export function checkFirstCaptcha(data) {
  return axiosFetch(`${apis.checkFirstCaptcha}`, 'post', data)
}
export function checkSecondCaptcha(data) {
  return axiosFetch(`${apis.checkSecondCaptcha}`, 'post', data)
}
export function resetDomainPassword(data) {
  return axiosFetch(`${apis.resetDomainPassword}`, 'post', data)
}
export function getCaptchaParams(data) {
  return axiosFetch(`${apis.captchaParams}`, 'get', data)
}
export function getCallback(data) {
  return axiosFetch(`${apis.callback}`, 'get', data)
}
export function getIdmapping(data) {
  return axiosFetch(`${apis.idmapping}`, 'get', data)
}
export function getLogout(data) {
  return axiosFetch(`${apis.logout}`, 'get', data)
}
export function getLogoutInfo(data) {
  return axiosFetch(`${apis.logoutInfo}`, 'post', data)
}
export function getAccountInfo(data) {
  return axiosFetch(`${apis.accountInfo}`, 'post', data)
}
export function getSecondSendVerifyCode(data) {
  return axiosFetch(`${apis.secondSendVerifyCode}`, 'post', data)
}
export function checkSecondVerifyCode(data) {
  return axiosFetch(`${apis.validateVerifyCode}`, 'post', data)
}
export function uniportalLogin(data) {
  return axiosFetch(`${apis.uniportalLogin}`, 'post', data)
}
export function setUniportalLink(data) {
  return axiosFetch(`${apis.setUniportalLink}${data}`, 'get')
}
export function smsLoginSmsSendFn(data) {
  return axiosFetch(`${apis.smsLoginSmsSend}`, 'post', data)
}
export function smsLoginFn(data) {
  return axiosFetch(`${apis.smsLogin}`, 'post', data)
}
export function getCountryCodeList() {
  return axiosFetch(`${apis.countryCodeList}`, 'get', {})
}
export function killCookieFn() {
  return axiosFetch(`${apis.killCookie}`, 'get', {})
}
export function meServerUrlFn(data) {
  return axiosFetch(`${apis.meServerUrl}`, 'get', data)
}
export function checkRange(data) {
  return axiosFetch(`${apis.checkRange}`, 'get', data)
}
export function axiosFetch(url, type, params) {
  if (type === 'post') {
    return instance.post(url, params || {}).then((res) => {
      return res.data
    })
  } else {
    return instance.get(url, { params: params || {} }).then((res) => {
      return res.data
    })
  }
}
export function getSpesInfo() {
  return axiosFetch(`${apis.getSpesInfo}`, 'get')
}
export function xGateGetSpesInfo(data) {
  return axiosFetch(`${apis.xGateGetSpesInfo}`, 'post', data, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
export function spesLogin(data) {
  return axiosFetch(`${apis.spesLogin}`, 'post', data)
}
