<template>
  <div class="header-ui">
    <div
      class="right"
      v-if="showLang"
    >
      <div
        class="menu-item"
        href="javascript:;"
      >
        <span
          class="titleSpan"
          :title="currentLanguage"
        >
          <span class="language">
            <img
              src="../../assets/img/language_logo.png"
              alt=""
            />
          </span>
          <span class="text">{{ currentLanguage }}</span>
          <span class="line">
            <img
              src="../../assets/img/language_arrow.png"
              alt=""
            />
          </span>
        </span>
        <ul>
          <li
            v-for="item in options"
            :key="item.value"
            @click="handelChange(item.value)"
          >
            <a
              href="javascript:;"
              :class="item.value === currentLang ? 'router-link-exact-active' : ''"
            >{{
              item.label
            }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="right-mobile">
      <div>
        <IconSandwichCollapse></IconSandwichCollapse>
      </div>
      <div>
        <div
          class="lineIcon"
          v-if="btnStatus"
        >
          <span></span>
          <span></span>
        </div>
        <div
          class="cancelIcon"
          v-else
        >
          <IconClose></IconClose>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { routes } from '../../router'
import { links, changeLang } from '@/api'
import { IconSandwichCollapse, IconClose } from '@aurora/vue-icon'
import { getLanguageList } from '@/utils/utils'
export default {
  components: {
    IconSandwichCollapse: IconSandwichCollapse(),
    IconClose: IconClose()
  },
  data() {
    return {
      options: [],
      links,
      currentLang: 'zh_CN',
      currentLanguage: '简体中文',
      routes: routes.filter((item) => {
        return item.meta && item.meta.title && !item.meta.noMenu
      }),
      isActive: false,
      btnStatus: true,
      showLang: true
    }
  },
  watch: {},
  created() {
    const vm = this
    const data = getLanguageList()
    data.map((item, i) => {
      const options = {}
      for (const i in item) {
        options.value = i
        options.label = item[i]
        if (i === this.$i18n.locale) {
          this.currentLang = i
          this.currentLanguage = item[i]
        }
      }
      this.options.push(options)
    })
    this.$router.beforeEach((to, from, next) => {
      if (to.name == 'resetRedirect') {
        this.showLang = false
      } else {
        this.showLang = true
      }
      next();
    });
  },
  methods: {
    handelChange(value) {
      document.cookie = 'lang = ' + value
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  display: inline-block;
  height: 24px;
  width: 109.8px;
  background-repeat: no-repeat;
  background-size: contain;
}
.header-ui {
  height: 28px;
  line-height: 28px;
  padding: 0 16px;
  background: #000;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 768px) {
  //pc端
  .right-mobile {
    display: none;
  }
  .right {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10%;
    height: 100%;
    color: #fff;
    span {
      cursor: pointer;
    }
    .language img {
      width: 14px;
      height: 14px;
      vertical-align: middle;
    }
    .line img {
      width: 10px;
      vertical-align: middle;
    }
    .text {
      font-family: MicrosoftYaHei-Regular;
      font-size: 12px;
      line-height: 16px;
      padding: 0 5px 0 10px;
    }
  }
  .menu-item {
    position: relative;
    cursor: pointer;
    .titleSpan {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 160px;
      opacity: 0.8;
    }
    > img {
      margin-right: 5px;
    }
    &:hover {
      border: 0 none;
      > ul {
        display: block;
        width: auto;
        position: absolute;
        top: 100%;
        left: 0;
        margin: 0;
        padding: 3px;
        padding-top: 16px;
        border-radius: 4px;
        background: #191919;
        font-size: 14px;
        li {
          list-style: none;
          height: 32px;
          text-align: left;
          > a {
            display: block;
            padding: 0 2em 0 1em;
            line-height: 2;
            word-break: keep-all;
            white-space: nowrap;
            text-align: initial;
            color: #ccc;
            &:hover,
            &.router-link-exact-active {
              opacity: 1;
              color: #fff;
            }
          }
        }
      }
    }
  }
  ul {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  // 移动端
  .right {
    display: none;
  }
  .header-ui {
    height: 40px;
    line-height: 40px;
    background: #fff;
    border-bottom: 1px solid #d8d8d8;
  }
  .right-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .lineIcon {
      width: 18px;
      line-height: 0;
      span {
        display: inline-block;
        width: 16px;
        height: 0;
        border: 1px solid #000;
        margin: 3px 0;
      }
    }
  }
}
</style>
