<template>
  <div class="main_wrap">
    <div class="m_header">
      <img :src="logoMobile" alt="" class="m_header_logo" v-if="isUniportal">
      <span class="logo" v-else></span>
      <icon-pause
        class="icon_expand"
        @click="openMenu"
      ></icon-pause>
    </div>
  </div>
</template>
<script>
import { IconPause } from '@aurora/vue-icon'
export default {
  components: {
    IconPause: IconPause()
  },
  props: ['popupMenu', 'logoMobile'],
  data() {
    return {
    }
  },
  computed: {
    isUniportal() {
      return this.$route.name == 'uniportalAccount'
    },
  },
  methods: {
    openMenu() {
      this.popupMenu.show()
    }
  }
}
</script>

<style lang="scss" scoped>
.main_wrap {
  display: none;
}
@media screen and (max-width: 767px) {
  .main_wrap {
    display: block;
    padding: 8px 27px 12px 16px;
    text-align: center;
    border-bottom: 1px solid #d8d8d8;
    .icon_expand {
      font-size: 20px;
      transform: rotate(-90deg);
      cursor: pointer;
    }
  }
  .m_header {
    display: flex;
    justify-content: space-between;
    .logo {
      flex: 0 0 auto;
      width: 100px;
      background-image: url('../../assets/img/hw_logo.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }
}
.m_header_logo {
  width: auto;
  height: 40px;
}
</style>
