import * as Vue3 from 'vue'
import App from './App.vue'
import initI18n from './i18n'
import router from './router'
import AUI from '@aurora/vue' // 直接从 @aurora/vue 里引入包含完整组件的 AUI 对象

import { getCookie } from '@/utils/utils'
import '@/assets/css/aui.scss'
import '@/assets/css/sso-message.scss'
import '@/assets/js/idss-fingerprint.js'

// Vue3.use(AUI) // 通过 Vue.use 方法加载整个 Aurora UI 组件

const app = Vue3.createApp(App)

/* AUI-INJECT-START-IHELP */
/* AUI-INJECT-END-IHELP */
const lang = getCookie('lang')
app.use(initI18n({ locale: lang }))

app.use(router).mount('#app')
