import { createRouter, createWebHashHistory } from 'vue-router'
import { getCookie, getQueryValue, getQueryVal } from '@/utils/utils'
import { US } from '@/i18n/en_US'
import { CN } from '@/i18n/zh_CN'
import { getHwidPage } from '@/api'

const lang = getCookie('lang') || 'zh_CN'
const messages = {
  'en_US': US,
  'zh_CN': CN
}
// prettier-ignore
export const routes = [

  /* AUI-INJECT-START-ROUTES */
  {
    path: '/',
    redirect: '/account'
  },
  {
    path: '/account',
    name: 'account',
    meta: { title: messages[lang].login },
    component: () => import( /* webpackChunkName: "hours" */ '../views/login/HwLogin.vue')
  },
  {
    path: '/w3Account',
    name: 'w3Account',
    meta: { title: messages[lang].login },
    component: () => import( /* webpackChunkName: "hours" */ '../views/login/W3Login.vue')
  },
  {
    path: '/uniportalAccount',
    name: 'uniportalAccount',
    meta: { title: messages[lang].login },
    component: () => import( /* webpackChunkName: "hours" */ '../views/login/UniportalLogin.vue')
  },
  {
    path: '/aboutPrivacy',
    name: 'aboutPrivacy',
    meta: { title: messages[lang].ppArgee },
    component: () => import( /* webpackChunkName: "hours" */ '../views/agreement/About.vue')
  },
  {
    path: '/detailPrivacy',
    name: 'detailPrivacy',
    meta: { title: messages[lang].ppArgee },
    component: () => import( /* webpackChunkName: "hours" */ '../views/agreement/Detail.vue')
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    meta: { title: messages[lang].ppArgee },
    component: () => import( /* webpackChunkName: "hours" */ '../views/agreement/PrivacyPolicy.vue')
  },
  {
    path: '/redirectionPage',
    name: 'redirectionPage',
    meta: { title: messages[lang].ppArgee },
    component: () => import( /* webpackChunkName: "hours" */ '../views/login/RedirectionPage.vue')
  },
  {
    path: '/common',
    name: 'common',
    meta: { title: messages[lang].login },
    component: () => import( /* webpackChunkName: "hours" */ '../views/login/Common.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { title: messages[lang].login },
    component: () => import( /* webpackChunkName: "hours" */ '../views/logout/Logout.vue')
  },
  // 修改密码&重置密码
  {
    path: '/resetPwd',
    name: 'resetPwd',
    meta: { title: messages[lang].changePassword },
    component: () => import( /* webpackChunkName: "hours" */ '../views/password/ResetPwd.vue')
  },
  {
    path: '/pwdTips',
    name: 'pwdTips',
    meta: { title: messages[lang].warmTips },
    component: () => import( /* webpackChunkName: "hours" */ '../views/password/PwdTips.vue')
  },
  {
    path: '/loginLoading',
    name: 'loginLoading',
    meta: { title: messages[lang].warmTips },
    component: () => import( /* webpackChunkName: "hours" */ '../views/login/loginLoading.vue')
  },
  {
    path: '/logoutLoading',
    name: 'logoutLoading',
    meta: { title: messages[lang].warmTips },
    component: () => import( /* webpackChunkName: "hours" */ '../views/logout/logoutLoading.vue')
  },
  // 二次认证
  {
    path: '/certification',
    name: 'certification',
    meta: { title: messages[lang].certificationTitle },
    component: () => import( /* webpackChunkName: "hours" */ '../views/certification/CertificationContent.vue')
  },
  {
    path: '/resetRedirect',
    name: 'resetRedirect',
    meta: { title: messages[lang].forgetPwd },
    component: () => import( /* webpackChunkName: "hours" */ '../views/password/ResetRedirect.vue')
  },
  {
    path: '/error',
    name: 'error',
    meta: { title: messages[lang].permissionError },
    component: () => import( /* webpackChunkName: "hours" */ '../views/login/ErrorLogin.vue')
  },
  {
    path: '/403',
    name: '403',
    meta: { title: messages[lang].error403Title },
    component: () => import( /* webpackChunkName: "hours" */ '../views/error/403.vue')
  },
  {
    path: '/expiredPassword',
    meta: { title: messages[lang].passwordEnd },
    children: [
      {
        path: 'index',
        name: 'expiredPassword',
        component: () => import( /* webpackChunkName: "expiredPassword" */ '../views/expiredPassword/index.vue')
      }
    ]
  },
  {
    path: '/expiringPassword',
    meta: { title: messages[lang].passwordExpiring },
    children: [
      {
        path: 'index',
        name: 'expiringPassword',
        component: () => import( /* webpackChunkName: "passwordExpiring" */ '../views/expiringPassword/index.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

function onlyShowW3Account(to, from, next) {
  // 内网只允许/w3Account地址显示
  if(process.env.VUE_APP_ISINTRA == 'true') {
    if(to.path == '/uniportalAccount' || to.path == '/account' || to.path == '/uniportalaccount') {
      router.push(`/w3Account?service=${getQueryValue('service', true)}&tenantId=${getQueryVal('tenantId')}`);
    } else {
      next()
    }
    next()
  }
}
router.beforeEach(function (to, from, next) {
  const queryString = `service=${getQueryValue('service', true)}&tenantId=${getQueryVal('tenantId')}`;
  onlyShowW3Account(to, from, next)
  if (window.localStorage && window.localStorage.getItem('hisPage') === 'w3' && to.path === '/account') {
    window.localStorage.setItem('hisPage', '')

    router.push(`/w3Account?${queryString}`);
  } else if (window.localStorage && window.localStorage.getItem('hisPage') === 'uniportal' 
    && to.path === '/account') {
    window.localStorage.setItem('hisPage', '')

    router.push(`/uniportalAccount?${queryString}`);
  } else if (
    window.localStorage &&
    window.localStorage.getItem('hisPage') === 'hw' &&
    (to.path === '/w3Account' || to.path === '/uniportalAccount' || to.path === '/resetPwd')
  ) {
    window.localStorage.setItem('hisPage', '')

    router.push(`/account?${queryString}`);
  } else if (window.location.hash !== '' && !window.location.hash.includes('?') 
    && window.location.search !== '') {
    var url = window.location.origin + window.location.pathname
    url = url + window.location.hash + window.location.search
    window.location.href = url
  } else if (to.path === '/account' && (from.path === '/resetRedirect' || from.path === '/resetPwd')) {
    // 其它页面跳转到hwid页面，资源文件不能全部加载，通过刷新页面解决
    location.reload()
  } else if (to.path === '/account') {
    getHwidPage().then((res) => {
      if (res.hwidLogin == 'true') {
        next()
      } else {
        router.push(`/uniportalAccount?${queryString}`);
      }
    })
  } else {
    next()
  }
})
export default router
