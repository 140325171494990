(function fingerprint() {
  window["idss_fp"] = {};
  window["idss_fp"]["fp"] = {};
  window["idss_fp"]["fp"]["version"] = "1.7.4";
  window["idss_fp"]["fp"]["cid"] = "";
  window["idss_fp"]["fp"]["data"] = {};
  window["idss_fp"]["fp"]["meta"] = {
    timeStart: 0,
    time: 0,
    domFp: "not ready",
    domFpStart: 0,
    domFpTime: 0,
  };
  window["idss_fp"].isReady = false;

  var writeCookie = true;

  window["idss_fp"].getfp = function () {
    throw new Error("This method is no longer supported!");
  };

  var fp = {
    canvas: "",
    webgl: "",
    // audio: "",
    ips: [],
    devs: [],
    epl: 0,
    ep: "",
    epls: "",
    fonts: "",
    // indexdb: false,
    erd: "",
  };

  genFp();

  window.addEventListener("load", function () {
    getFpRelatedDom();
  });

  function genFp() {
    var begin = new Date();

    try {
      window["idss_fp"]["fp"]["cid"] = getUuid();
    } catch (err) {
      window["idss_fp"]["fp"]["cid"] = "err: " + err.message;
    }

    try {
      // canvas
      var cp = canvasfp();

      if (cp) {
        fp["canvas"] = murmur(cp);
      }

      pluginsfp();

      navigatorfp(fp);

      fp["math"] = murmur(JSON.stringify(getMathFingerprint()));

      var tsfp = getTouchSupport();
      fp["nts"] = murmur(JSON.stringify(tsfp));
      fp["nmtp"] = tsfp.maxTouchPoints;
    } catch (err) {
      fp["error"] = err.message;
    }

    window["idss_fp"]["fp"]["data"] = fp;

    window["idss_fp"]["fp"]["meta"].timeStart = +begin;
    window["idss_fp"]["fp"]["meta"].time = new Date() - begin;
  }

  function getFpRelatedDom() {
    if (window["idss_fp"].isReady === true) {
      return;
    }

    var begin = new Date();
    window["idss_fp"]["fp"]["meta"].domFpStart = +begin;

    try {
      var wp = webglfp();
      if (wp) {
        fp["webgl"] = murmur(wp);
      }

      var ft = fontsfp();
      if (ft) {
        fp["fonts"] = murmur(fontsfp().join(","));
      }

      fp["bsh"] = document["body"]["clientHeight"];
      fp["bsw"] = document["body"]["clientWidth"];
    } catch (err) {
      fp["dom_error"] = err.message;
    }

    window["idss_fp"]["fp"]["meta"].domFpTime = new Date() - begin;

    window["idss_fp"].isReady = true;
    window["idss_fp"]["fp"]["meta"].domFp = "ready";
  }

  function getUuid() {
    var uuid = getCookie("idss_cid");
    if (!uuid) {
      uuid = genUuid();
    }
    setCookie("idss_cid", uuid);
    return uuid;
  }

  function genUuid() {
    if (window.crypto && window.crypto.randomUUID) {
      return window.crypto.randomUUID();
    }
    var s = [];
    var hex = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
      const crypto = window.crypto || window.msCrypto
      let array = new Uint32Array(1)
      let Rand = crypto.getRandomValues(array)
      let mineId = Math.round(Rand / 100)
      s.push(hex.substr(Math.floor(mineId * 0x10), 1));
    }
    s[14] = "4";
    s[19] = hex.substr((s[19] & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = "-";
    var uuid = s.join("");
    return uuid;
  }

  function getCookie(key) {
    try {
      if (navigator.cookieEnabled) {
        var value = "";
        if (window.localStorage) {
          value = window.localStorage.getItem(key);
        }
        if (value) {
          return value;
        }
        var name = key + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i].trim();
          if (c.indexOf(name) === 0) {
            value = c.substring(name.length, c.length);
            if (value) {
              return value;
            }
          }
        }
      }
    } catch (err) {
      // 不处理
    }
    return "";
  }

  function setCookie(key, value) {
    if (navigator.cookieEnabled && window.localStorage) {
      window.localStorage.setItem(key, value);
    }

    if (navigator.cookieEnabled && writeCookie) {
      var t = new Date();
      var expiresDays = 90;
      t.setTime(t.getTime() + expiresDays * 24 * 3600 * 1000);

      var domain = "";
      var domains = location.host.split(".");
      if (domains.length >= 2) {
        domain =
          domains[domains.length - 2] + "." + domains[domains.length - 1];
        // 写入
        document.cookie =
          key +
          "=" +
          value +
          ";expires=" +
          t.toGMTString() +
          ";domain=" +
          domain +
          ";path=/";
      }
    }
  }

  function isIE() {
    if (navigator["appName"] === "Microsoft Internet Explorer") {
      return true;
    } else if (
      navigator["appName"] === "Netscape" &&
      /Trident/.test(navigator["userAgent"])
    ) {
      // IE 11
      return true;
    }
    return false;
  }

  // 不随firefox缩放变化的canvas指纹
  function canvasfp() {
    try {
      var canvas = document["createElement"]("canvas");

      var ctx = canvas.getContext("2d");

      canvas.setAttribute("width", 400); // 220
      canvas.setAttribute("height", 60); // 30
      ctx.textBaseline = "top";
      ctx.font = "14px 'Arial'";
      ctx.textBaseline = "alphabetic";
      ctx.fillStyle = "#f60";
      ctx.fillRect(125, 1, 62, 20);
      ctx.fillStyle = "#069";
      var printedText =
        "Cwm fjordbank gly " + String.fromCharCode(55357, 56835); /* 😃 */
      ctx.fillText(printedText, 2, 15);
      ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
      ctx.fillText(printedText, 4, 17);

      var img = canvas.toDataURL();
      if (!img) {
        img = "";
      }

      var r = atob(img.replace("data:image/png;base64,", ""));

      return r;
    } catch (n) {
      return "";
    }
  }

  function each(myObj, iterator) {
    if (Array.prototype.forEach && myObj.forEach === Array.prototype.forEach) {
      myObj.forEach(iterator);
    } else if (myObj.length === +myObj.length) {
      for (var i = 0, l = myObj.length; i < l; i++) {
        iterator(myObj[i], i, myObj);
      }
    } else {
      for (var key in myObj) {
        // 改动 myObj.hasOwnProperty(key) => Object.prototype.hasOwnProperty.call(myObj, key)
        if (Object.prototype.hasOwnProperty.call(myObj, key)) {
          iterator(myObj[key], key, myObj);
        }
      }
    }
  }

  function audioKey(fp) {
    if (navigator.userAgent.match(/OS 11.+Version\/11.+Safari/)) {
      fp["audio"] = "Excluded IOS11";
      return;
    }

    var AudioContext =
      window.OfflineAudioContext || window.webkitOfflineAudioContext;

    if (AudioContext === null || AudioContext === undefined) {
      fp["audio"] = "";
      return;
    }

    var ctx = new AudioContext(1, 44100, 44100);

    var cillator = ctx.createOscillator();
    cillator.type = "triangle";
    cillator.frequency.setValueAtTime(10000, ctx.currentTime);

    var compressor = ctx.createDynamicsCompressor();
    each(
      [
        ["threshold", -50],
        ["knee", 40],
        ["ratio", 12],
        ["reduction", -20],
        ["attack", 0],
        ["release", 0.25],
      ],
      function (item) {
        if (
          compressor[item[0]] !== undefined &&
          typeof compressor[item[0]].setValueAtTime === "function"
        ) {
          compressor[item[0]].setValueAtTime(item[1], ctx.currentTime);
        }
      }
    );

    cillator.connect(compressor);
    compressor.connect(ctx.destination);
    cillator.start(0);
    ctx.startRendering();

    var audioTimeoutId = setTimeout(function () {
      ctx = null;
      fp["audio"] = "audioTimeout";
    }, 1000);

    ctx.oncomplete = function (event) {
      var audiofp;
      try {
        clearTimeout(audioTimeoutId);
        audiofp = event.renderedBuffer
          .getChannelData(0)
          .slice(4500, 5000)
          .reduce(function (acc, val) {
            return acc + Math.abs(val);
          }, 0)
          .toString();
        cillator.disconnect();
        compressor.disconnect();
      } catch (error) {
        fp["audio"] = error;
      }
      fp["audio"] = audiofp;
    };
  }

  function getregularplugins() {
    var result = [];
    var plugins = [];
    for (var i = 0, l = navigator["plugins"]["length"]; i < l; i++) {
      plugins["push"](navigator["plugins"][i]);
    }

    for (var i2 = 0; i2 < plugins["length"]; i2++) {
      var r = [];
      for (var j = 0; j < plugins[i2]["length"]; j++) {
        r.push(plugins[i2]["item"](j)["type"]);
      }

      var temp = plugins[i2]["name"];

      if (plugins[i2]["version"]) {
        temp = temp + plugins[i2]["version"];
      }

      temp = temp + plugins[i2]["filename"] + r.join("");

      result["push"](temp);
    }

    return result;
  }

  function getieplugins() {
    var result = [];
    if (
      (Object.getOwnPropertyDescriptor &&
        Object.getOwnPropertyDescriptor(window, "ActiveXObject")) ||
      "ActiveXObject" in window
    ) {
      var names = [
        "AcroPDF.PDF", // Adobe PDF reader 7+
        "Adodb.Stream",
        "AgControl.AgControl", // Silverlight
        "DevalVRXCtrl.DevalVRXCtrl.1",
        "MacromediaFlashPaper.MacromediaFlashPaper",
        "Msxml2.DOMDocument",
        "Msxml2.XMLHTTP",
        "PDF.PdfCtrl", // Adobe PDF reader 6 and earlier, brrr
        "QuickTime.QuickTime", // QuickTime
        "QuickTimeCheckObject.QuickTimeCheck.1",
        "RealPlayer",
        "RealPlayer.RealPlayer(tm) ActiveX Control (32-bit)",
        "RealVideo.RealVideo(tm) ActiveX Control (32-bit)",
        "Scripting.Dictionary",
        "SWCtl.SWCtl", // ShockWave player
        "Shell.UIHelper",
        "ShockwaveFlash.ShockwaveFlash", // flash plugin
        "Skype.Detection",
        "TDCCtl.TDCCtl",
        "WMPlayer.OCX", // Windows media player
        "rmocx.RealPlayer G2 Control",
        "rmocx.RealPlayer G2 Control.1",
      ];

      var detectplugin = function (name) {
        try {
          new ActiveXObject(name); // eslint-disable-no-new
          return name;
        } catch (e) {
          return null;
        }
      };

      // starting to detect plugins in IE
      for (var i = 0; i < names.length; i++) {
        if (detectplugin(names[i])) {
          var ax = new ActiveXObject(names[i]);
          var v = "";
          var v1 = "";

          try {
            v = ax["GetVariable"]("$version");
          } catch (ex) {}

          try {
            v1 = ax["GetVersions"]();
          } catch (ex) {}

          result.push(names[i] + v + v1);
        }
      }
    }

    if (navigator.plugins) {
      result = result.concat(getregularplugins());
    }

    return result;
  }

  function pluginsfp() {
    var result;

    if (isIE()) {
      result = getieplugins();
    } else {
      result = getregularplugins();
    }

    fp["epl"] = result.length;
    fp["ep"] = murmur(result.join(","));

    var group = {};

    for (var i = 0; i < result.length; i++) {
      var key = result[i]["charAt"](0)["toUpperCase"]();
      if (!(key >= "A" && key <= "Z")) {
        key = "#";
      }

      if (!Object.prototype.hasOwnProperty.call(group, key)) {
        group[key] = [];
      }

      group[key].push(result[i]);
    }

    var r = [];

    for (var key2 in group) {
      if (Object.prototype.hasOwnProperty.call(group, key2)) {
        r.push(key2 + murmur(group[key2].join(",")));
      }
    }

    fp["epls"] = r.join(",");
  }

  function getwebglcanvas() {
    var canvas = document.createElement("canvas");
    var gl = null;
    try {
      gl =
        canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
    } catch (e) {
      // squelch
    }
    if (!gl) {
      gl = null;
    }
    return gl;
  }

  function pushGlResult1(result, gl, fa2s, maxAnisotropy) {
    result.push("extensions:" + gl.getSupportedExtensions().join(";"));
    result.push(
      "webgl aliased line width range:" +
        fa2s(gl.getParameter(gl.ALIASED_LINE_WIDTH_RANGE))
    );
    result.push(
      "webgl aliased point size range:" +
        fa2s(gl.getParameter(gl.ALIASED_POINT_SIZE_RANGE))
    );
    result.push("webgl alpha bits:" + gl.getParameter(gl.ALPHA_BITS));
    result.push(
      "webgl antialiasing:" +
        (gl.getContextAttributes().antialias ? "yes" : "no")
    );
    result.push("webgl blue bits:" + gl.getParameter(gl.BLUE_BITS));
    result.push("webgl depth bits:" + gl.getParameter(gl.DEPTH_BITS));
    result.push("webgl green bits:" + gl.getParameter(gl.GREEN_BITS));
    result.push("webgl max anisotropy:" + maxAnisotropy(gl));
    result.push(
      "webgl max combined texture image units:" +
        gl.getParameter(gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS)
    );
    result.push(
      "webgl max cube map texture size:" +
        gl.getParameter(gl.MAX_CUBE_MAP_TEXTURE_SIZE)
    );
    result.push(
      "webgl max fragment uniform vectors:" +
        gl.getParameter(gl.MAX_FRAGMENT_UNIFORM_VECTORS)
    );
    result.push(
      "webgl max render buffer size:" +
        gl.getParameter(gl.MAX_RENDERBUFFER_SIZE)
    );
    result.push(
      "webgl max texture image units:" +
        gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS)
    );
    result.push(
      "webgl max texture size:" + gl.getParameter(gl.MAX_TEXTURE_SIZE)
    );
    result.push(
      "webgl max varying vectors:" + gl.getParameter(gl.MAX_VARYING_VECTORS)
    );
    result.push(
      "webgl max vertex attribs:" + gl.getParameter(gl.MAX_VERTEX_ATTRIBS)
    );
    result.push(
      "webgl max vertex texture image units:" +
        gl.getParameter(gl.MAX_VERTEX_TEXTURE_IMAGE_UNITS)
    );
    result.push(
      "webgl max vertex uniform vectors:" +
        gl.getParameter(gl.MAX_VERTEX_UNIFORM_VECTORS)
    );
    result.push(
      "webgl max viewport dims:" + fa2s(gl.getParameter(gl.MAX_VIEWPORT_DIMS))
    );
    result.push("webgl red bits:" + gl.getParameter(gl.RED_BITS));
    result.push("webgl renderer:" + gl.getParameter(gl.RENDERER));
    result.push(
      "webgl shading language version:" +
        gl.getParameter(gl.SHADING_LANGUAGE_VERSION)
    );
    result.push("webgl stencil bits:" + gl.getParameter(gl.STENCIL_BITS));
    result.push("webgl vendor:" + gl.getParameter(gl.VENDOR));
    result.push("webgl version:" + gl.getParameter(gl.VERSION));
  }

  function pushGlResult2(result, gl, fa2s, maxAnisotropy) {
    result.push(
      "webgl vertex shader high float precision:" +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_FLOAT).precision
    );
    result.push(
      "webgl vertex shader high float precision rangeMin:" +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_FLOAT).rangeMin
    );
    result.push(
      "webgl vertex shader high float precision rangeMax:" +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_FLOAT).rangeMax
    );
    result.push(
      "webgl vertex shader medium float precision:" +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_FLOAT).precision
    );
    result.push(
      "webgl vertex shader medium float precision rangeMin:" +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_FLOAT).rangeMin
    );
    result.push(
      "webgl vertex shader medium float precision rangeMax:" +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_FLOAT).rangeMax
    );
    result.push(
      "webgl vertex shader low float precision:" +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_FLOAT).precision
    );
    result.push(
      "webgl vertex shader low float precision rangeMin:" +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_FLOAT).rangeMin
    );
    result.push(
      "webgl vertex shader low float precision rangeMax:" +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_FLOAT).rangeMax
    );
    result.push(
      "webgl fragment shader high float precision:" +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT).precision
    );
    result.push(
      "webgl fragment shader high float precision rangeMin:" +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT).rangeMin
    );
    result.push(
      "webgl fragment shader high float precision rangeMax:" +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT).rangeMax
    );
    result.push(
      "webgl fragment shader medium float precision:" +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_FLOAT)
          .precision
    );
    result.push(
      "webgl fragment shader medium float precision rangeMin:" +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_FLOAT)
          .rangeMin
    );
    result.push(
      "webgl fragment shader medium float precision rangeMax:" +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_FLOAT)
          .rangeMax
    );
    result.push(
      "webgl fragment shader low float precision:" +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_FLOAT).precision
    );
    result.push(
      "webgl fragment shader low float precision rangeMin:" +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_FLOAT).rangeMin
    );
  }

  function pushGlResult3(result, gl, fa2s, maxAnisotropy) {
    result.push(
      "webgl fragment shader low float precision rangeMax:" +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_FLOAT).rangeMax
    );
    result.push(
      "webgl vertex shader high int precision:" +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_INT).precision
    );
    result.push(
      "webgl vertex shader high int precision rangeMin:" +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_INT).rangeMin
    );
    result.push(
      "webgl vertex shader high int precision rangeMax:" +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_INT).rangeMax
    );
    result.push(
      "webgl vertex shader medium int precision:" +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_INT).precision
    );
    result.push(
      "webgl vertex shader medium int precision rangeMin:" +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_INT).rangeMin
    );
    result.push(
      "webgl vertex shader medium int precision rangeMax:" +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_INT).rangeMax
    );
    result.push(
      "webgl vertex shader low int precision:" +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_INT).precision
    );
    result.push(
      "webgl vertex shader low int precision rangeMin:" +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_INT).rangeMin
    );
    result.push(
      "webgl vertex shader low int precision rangeMax:" +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_INT).rangeMax
    );
    result.push(
      "webgl fragment shader high int precision:" +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_INT).precision
    );
    result.push(
      "webgl fragment shader high int precision rangeMin:" +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_INT).rangeMin
    );
    result.push(
      "webgl fragment shader high int precision rangeMax:" +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_INT).rangeMax
    );
    result.push(
      "webgl fragment shader medium int precision:" +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_INT).precision
    );
    result.push(
      "webgl fragment shader medium int precision rangeMin:" +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_INT).rangeMin
    );
    result.push(
      "webgl fragment shader medium int precision rangeMax:" +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_INT).rangeMax
    );
    result.push(
      "webgl fragment shader low int precision:" +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_INT).precision
    );
    result.push(
      "webgl fragment shader low int precision rangeMin:" +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_INT).rangeMin
    );
    result.push(
      "webgl fragment shader low int precision rangeMax:" +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_INT).rangeMax
    );
  }

  function webglfp() {
    var gl;
    var fa2s = function (fa) {
      gl.clearColor(0.0, 0.0, 0.0, 1.0);
      gl.enable(gl.DEPTH_TEST);
      gl.depthFunc(gl.LEQUAL);
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
      return "[" + fa[0] + ", " + fa[1] + "]";
    };

    var maxAnisotropy = function (gl) {
      var anisotropy;
      var ext =
        gl.getExtension("EXT_texture_filter_anisotropic") ||
        gl.getExtension("WEBKIT_EXT_texture_filter_anisotropic") ||
        gl.getExtension("MOZ_EXT_texture_filter_anisotropic");
      if (ext) {
        anisotropy = gl.getParameter(ext.MAX_TEXTURE_MAX_ANISOTROPY_EXT);
        if (anisotropy === 0) {
          anisotropy = 2;
        }
        return anisotropy;
      }
      return null;
    };

    gl = getwebglcanvas();
    if (!gl) {
      return null;
    }

    var result = [];
    var vShaderTemplate =
      "attribute vec2 attrVertex;varying vec2 varyinTexCoordinate;uniform vec2 uniformOffset;" +
      "void main(){varyinTexCoordinate=attrVertex+uniformOffset;gl_Position=vec4(attrVertex,0,1);}";
    var fShaderTemplate =
      "precision mediump float;varying vec2 varyinTexCoordinate;" +
      "void main() {gl_FragColor=vec4(varyinTexCoordinate,0,1);}";

    var vertexPosBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexPosBuffer);
    var vertices = new Float32Array([
      -0.2, -0.9, 0, 0.4, -0.26, 0, 0, 0.732134444, 0,
    ]);
    gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);
    vertexPosBuffer.itemSize = 3;
    vertexPosBuffer.numItems = 3;
    var program = gl.createProgram();
    var vshader = gl.createShader(gl.VERTEX_SHADER);
    gl.shaderSource(vshader, vShaderTemplate);
    gl.compileShader(vshader);
    var fshader = gl.createShader(gl.FRAGMENT_SHADER);
    gl.shaderSource(fshader, fShaderTemplate);
    gl.compileShader(fshader);
    gl.attachShader(program, vshader);
    gl.attachShader(program, fshader);
    gl.linkProgram(program);
    gl.useProgram(program);
    program.vertexPosAttrib = gl.getAttribLocation(program, "attrVertex");
    program.offsetUniform = gl.getUniformLocation(program, "uniformOffset");
    gl.enableVertexAttribArray(program.vertexPosArray);
    gl.vertexAttribPointer(
      program.vertexPosAttrib,
      vertexPosBuffer.itemSize,
      gl.FLOAT,
      !1,
      0,
      0
    );
    gl.uniform2f(program.offsetUniform, 1, 1);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, vertexPosBuffer.numItems);
    if (gl.canvas !== null) {
      result.push(gl.canvas.toDataURL());
    }

    pushGlResult1(result, gl, fa2s, maxAnisotropy);
    if (!gl.getShaderPrecisionFormat) {
      return result.join("~");
    }
    pushGlResult2(result, gl, fa2s, maxAnisotropy);
    pushGlResult3(result, gl, fa2s, maxAnisotropy);
    return result.join("~");
  }

  function getTimezone() {
    var DateTimeFormat = null;
    if (window.Intl) {
      DateTimeFormat = window.Intl.DateTimeFormat;
    }
    if (DateTimeFormat) {
      var timezone = new DateTimeFormat().resolvedOptions().timeZone;
      if (timezone) {
        return timezone;
      }
    }

    return "";
  }

  function getTimezoneOffset() {
    var currentYear = new Date().getFullYear();
    return Math.max(
      parseFloat(new Date(currentYear, 0, 1).getTimezoneOffset()),
      parseFloat(new Date(currentYear, 6, 1).getTimezoneOffset())
    );
  }

  function navigatorfp(fp) {
    fp["nacn"] = navigator["appCodeName"];
    fp["nan"] = navigator["appName"];
    fp["nce"] = navigator["cookieEnabled"];
    fp["nlg"] = navigator["language"];
    fp["npf"] = navigator["platform"]["split"](" ").shift();

    if (
      navigator["mediaDevices"] &&
      navigator["mediaDevices"]["enumerateDevices"]
    ) {
      navigator["mediaDevices"]["enumerateDevices"]().then(function (t) {
        var n = t.map(function (e) {
          return e.deviceId;
        });
        fp["erd"] = n.join(",");
      });
    }

    fp["sh"] = window["screen"]["height"];
    fp["sw"] = window["screen"]["width"];
    fp["sah"] = window["screen"]["availHeight"];
    fp["saw"] = window["screen"]["availWidth"];
    fp["scd"] = window["screen"]["colorDepth"];

    fp["bsh"] = 0;
    fp["bsw"] = 0;

    fp["etz"] = getTimezone();
    fp["etzo"] = getTimezoneOffset();

    fp["ndm"] = navigator["deviceMemory"] || "";
    fp["ua"] = navigator["userAgent"];
    fp["nhc"] = navigator["hardwareConcurrency"] || "";

    fp["nwd"] = navigator["webdriver"];
  }

  function base64encode(e) {
    var n = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

    if (!e) return "";
    for (var t, r, a, i, o, s, c, l = "", u = 0; u < e.length; ) {
      t = e["charCodeAt"](u++);
      r = e["charCodeAt"](u++);
      a = e["charCodeAt"](u++);
      i = t >> 2;
      o = ((3 & t) << 4) | (r >> 4);
      s = ((15 & r) << 2) | (a >> 6);
      c = 63 & a;
      isNaN(r) ? (s = c = 64) : isNaN(a) && (c = 64);
      l = l + n["charAt"](i) + n["charAt"](o) + n["charAt"](s) + n["charAt"](c);
    }

    return l;
  }

  function getFontList() {
    return [
      "Abadi MT Condensed Light",
      "Adobe Fangsong Std",
      "Adobe Hebrew",
      "Adobe Ming Std",
      "Agency FB",
      "Arab",
      "Arabic Typesetting",
      "Arial Black",
      "Batang",
      "Bauhaus 93",
      "Bell MT",
      "Bitstream Vera Serif",
      "Bodoni MT",
      "Bookman Old Style",
      "Braggadocio",
      "Broadway",
      "Calibri",
      "Californian FB",
      "Castellar",
      "Casual",
      "Centaur",
      "Century Gothic",
      "Chalkduster",
      "Colonna MT",
      "Copperplate Gothic Light",
      "DejaVu LGC Sans Mono",
      "Desdemona",
      "DFKai-SB",
      "Dotum",
      "Engravers MT",
      "Eras Bold ITC",
      "Eurostile",
      "FangSong",
      "Forte",
      "Franklin Gothic Heavy",
      "French Script MT",
      "Gabriola",
      "Gigi",
      "Gisha",
      "Goudy Old Style",
      "Gulim",
      "GungSeo",
      "Haettenschweiler",
      "Harrington",
      "Hiragino Sans GB",
      "Impact",
      "Informal Roman",
      "KacstOne",
      "Kino MT",
      "Kozuka Gothic Pr6N",
      "Lohit Gujarati",
      "Loma",
      "Lucida Bright",
      "Lucida Fax",
      "Magneto",
      "Malgun Gothic",
      "Matura MT Script Capitals",
      "Menlo",
      "MingLiU-ExtB",
      "MoolBoran",
      "MS PMincho",
      "MS Reference Sans Serif",
      "News Gothic MT",
      "Niagara Solid",
      "Nyala",
      "Palace Script MT",
      "Papyrus",
      "Perpetua",
      "Playbill",
      "PMingLiU",
      "Rachana",
      "Rockwell",
      "Sawasdee",
      "Script MT Bold",
      "Segoe Print",
      "Showcard Gothic",
      "SimHei",
      "Snap ITC",
      "TlwgMono",
      "Tw Cen MT Condensed Extra Bold",
      "Ubuntu",
      "Umpush",
      "Univers",
      "Utopia",
      "Vladimir Script",
      "Wide Latin",
    ];
  }

  // 此api可以参照阿里的实现
  function fontsfp() {
    var baseFonts = ["monospace", "sans-serif", "serif"];
    var fontList = getFontList();

    /*
     * we use m or w because these two characters take up the maximum width.
     * And we use a LLi so that the same matching fonts can get separated
     */
    var testString = "mmmmmmmmmmlli";

    // we test using 72px font size, we may use any size. I guess larger the better.
    var testSize = "72px";

    var h = document["getElementsByTagName"]("body")[0];

    // div to load spans for the base fonts
    var baseFontsDiv = document["createElement"]("div");

    // div to load spans for the fonts to detect
    var fontsDiv = document["createElement"]("div");

    var defaultWidth = {};
    var defaultHeight = {};

    // creates a span where the fonts will be loaded
    var createSpan = function () {
      var s = document["createElement"]("span");

      /*
       * We need this css as in some weird browser this
       * span elements shows up for a microSec which creates a
       * bad user experience
       */
      s["style"]["position"] = "absolute";
      s["style"]["left"] = "-9999px";
      s["style"]["fontSize"] = testSize;
      s["innerHTML"] = testString;
      return s;
    };

    // creates a span and load the font to detect and a base font for fallback
    var createSpanWithFonts = function (fontToDetect, baseFont) {
      var s = createSpan();
      s["style"]["fontFamily"] = fontToDetect + "," + baseFont;
      return s;
    };

    // creates spans for the base fonts and adds them to baseFontsDiv
    var initializeBaseFontsSpans = function () {
      var spans = [];
      for (
        var index = 0, length = baseFonts["length"];
        index < length;
        index++
      ) {
        var s = createSpan();
        s["style"]["fontFamily"] = baseFonts[index];
        baseFontsDiv["appendChild"](s);
        spans.push(s);
      }
      return spans;
    };

    // creates spans for the fonts to detect and adds them to fontsDiv
    var initializeFontsSpans = function () {
      var spans = {};
      for (var i = 0, l = fontList["length"]; i < l; i++) {
        var fontSpans = [];
        for (
          var j = 0, numDefaultFonts = baseFonts["length"];
          j < numDefaultFonts;
          j++
        ) {
          var s = createSpanWithFonts(fontList[i], baseFonts[j]);
          fontsDiv["appendChild"](s);
          fontSpans.push(s);
        }
        spans[fontList[i]] = fontSpans; // Stores {fontName : [spans for that font]}
      }
      return spans;
    };

    // checks if a font is available
    var isFontAvailable = function (fontSpans) {
      var detected = false;
      for (var i = 0; i < baseFonts.length; i++) {
        detected =
          fontSpans[i].offsetWidth !== defaultWidth[baseFonts[i]] ||
          fontSpans[i].offsetHeight !== defaultHeight[baseFonts[i]];
        if (detected) {
          return detected;
        }
      }
      return detected;
    };

    // create spans for base fonts
    var baseFontsSpans = initializeBaseFontsSpans();

    // add the spans to the DOM
    h.appendChild(baseFontsDiv);

    // get the default width for the three base fonts
    for (var index = 0, length = baseFonts.length; index < length; index++) {
      defaultWidth[baseFonts[index]] = baseFontsSpans[index].offsetWidth; // width for the default font
      defaultHeight[baseFonts[index]] = baseFontsSpans[index].offsetHeight; // height for the default font
    }

    // create spans for fonts to detect
    var fontsSpans = initializeFontsSpans();

    // add all the spans to the DOM
    h.appendChild(fontsDiv);

    // check available fonts
    var available = [];
    for (var i = 0, l = fontList.length; i < l; i++) {
      if (isFontAvailable(fontsSpans[fontList[i]])) {
        available.push(fontList[i]);
      }
    }

    // remove spans from DOM
    h.removeChild(fontsDiv);
    h.removeChild(baseFontsDiv);

    return available;
  }

  function serialize(obj) {
    var str = [];
    for (var p in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        if (obj[p]) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      }
    }

    str.sort();

    return str.join("&");
  }

  // nc()
  function crypt(r) {
    var cs = murmur(r);

    r = r + "&cs=" + cs;

    var factor = 211;
    var o = "";

    for (var i = 0; i < r.length; i++) {
      var f = (r["charCodeAt"](i) ^ (factor - 1)) & 255;
      o += String["fromCharCode"](f);
      factor = f;
    }

    o = base64encode(o);

    return o;
  }

  var flashvars = {
    enabled: false,
    sid: "",
    handler: void 0,
    flashready: false,
    indexdbready: false,
    dbready: false,
    webrtcdevready: false,
    webrtcipready: false,
  };

  function encode(e) {
    var t = e["replace"](/[\u0080-\u07ff]/g, function (e) {
      var t = e["charCodeAt"](0);
      return String["fromCharCode"](192 | (t >> 6), 128 | (63 & t));
    });
    return (t = t["replace"](/[\u0800-\uffff]/g, function (e) {
      var t = e["charCodeAt"](0);
      return String["fromCharCode"](
        224 | (t >> 12),
        128 | ((t >> 6) & 63),
        128 | (63 & t)
      );
    }));
  }

  function ROTL(e, t) {
    return (e << t) | (e >>> (32 - t));
  }

  function fXor(e, t, n, r) {
    switch (e) {
      case 0:
        return (t & n) ^ (~t & r);
      case 1:
        return t ^ n ^ r;
      case 2:
        return (t & n) ^ (t & r) ^ (n & r);
      case 3:
        return t ^ n ^ r;
      default:
        return 0;
    }
  }

  function toHexStr(e) {
    for (var t, n = "", r = 7; r >= 0; r--)
      (t = (e >>> (4 * r)) & 15), (n += t.toString(16));
    return n;
  }

  // MurmurHash3 related functions
  function x64Add(x, y) {
    x = [x[0] >>> 16, x[0] & 0xffff, x[1] >>> 16, x[1] & 0xffff];
    y = [y[0] >>> 16, y[0] & 0xffff, y[1] >>> 16, y[1] & 0xffff];
    var z = [0, 0, 0, 0];
    z[3] += x[3] + y[3];
    z[2] += z[3] >>> 16;
    z[3] &= 0xffff;
    z[2] += x[2] + y[2];
    z[1] += z[2] >>> 16;
    z[2] &= 0xffff;
    z[1] += x[1] + y[1];
    z[0] += z[1] >>> 16;
    z[1] &= 0xffff;
    z[0] += x[0] + y[0];
    z[0] &= 0xffff;
    return [(z[0] << 16) | z[1], (z[2] << 16) | z[3]];
  }

  function x64Multiply(x, y) {
    x = [x[0] >>> 16, x[0] & 0xffff, x[1] >>> 16, x[1] & 0xffff];
    y = [y[0] >>> 16, y[0] & 0xffff, y[1] >>> 16, y[1] & 0xffff];
    var z = [0, 0, 0, 0];
    z[3] += x[3] * y[3];
    z[2] += z[3] >>> 16;
    z[3] &= 0xffff;
    z[2] += x[2] * y[3];
    z[1] += z[2] >>> 16;
    z[2] &= 0xffff;
    z[2] += x[3] * y[2];
    z[1] += z[2] >>> 16;
    z[2] &= 0xffff;
    z[1] += x[1] * y[3];
    z[0] += z[1] >>> 16;
    z[1] &= 0xffff;
    z[1] += x[2] * y[2];
    z[0] += z[1] >>> 16;
    z[1] &= 0xffff;
    z[1] += x[3] * y[1];
    z[0] += z[1] >>> 16;
    z[1] &= 0xffff;
    z[0] += x[0] * y[3] + x[1] * y[2] + x[2] * y[1] + x[3] * y[0];
    z[0] &= 0xffff;
    return [(z[0] << 16) | z[1], (z[2] << 16) | z[3]];
  }

  function x64Rotl(x, y) {
    y %= 64;
    if (y === 32) {
      return [x[1], x[0]];
    } else if (y < 32) {
      return [
        (x[0] << y) | (x[1] >>> (32 - y)),
        (x[1] << y) | (x[0] >>> (32 - y)),
      ];
    } else {
      y -= 32;
      return [
        (x[1] << y) | (x[0] >>> (32 - y)),
        (x[0] << y) | (x[1] >>> (32 - y)),
      ];
    }
  }

  function x64LeftShift(x, y) {
    y %= 64;
    if (y === 0) {
      return x;
    } else if (y < 32) {
      return [(x[0] << y) | (x[1] >>> (32 - y)), x[1] << y];
    } else {
      return [x[1] << (y - 32), 0];
    }
  }

  function x64Xor(x, y) {
    return [x[0] ^ y[0], x[1] ^ y[1]];
  }

  function x64Fmix(hv) {
    hv = x64Xor(hv, [0, hv[0] >>> 1]);
    hv = x64Multiply(hv, [0xff51afd7, 0xed558ccd]);
    hv = x64Xor(hv, [0, hv[0] >>> 1]);
    hv = x64Multiply(hv, [0xc4ceb9fe, 0x1a85ec53]);
    hv = x64Xor(hv, [0, hv[0] >>> 1]);
    return hv;
  }

  function switchRemainder(remainder, keyVal, kv1, kv2, c1, c2, hv1, hv2, i) {
    switch (remainder) {
      case 15:
        kv2 = x64Xor(kv2, x64LeftShift([0, keyVal.charCodeAt(i + 14)], 48));
      // fallthrough
      case 14:
        kv2 = x64Xor(kv2, x64LeftShift([0, keyVal.charCodeAt(i + 13)], 40));
      // fallthrough
      case 13:
        kv2 = x64Xor(kv2, x64LeftShift([0, keyVal.charCodeAt(i + 12)], 32));
      // fallthrough
      case 12:
        kv2 = x64Xor(kv2, x64LeftShift([0, keyVal.charCodeAt(i + 11)], 24));
      // fallthrough
      case 11:
        kv2 = x64Xor(kv2, x64LeftShift([0, keyVal.charCodeAt(i + 10)], 16));
      // fallthrough
      case 10:
        kv2 = x64Xor(kv2, x64LeftShift([0, keyVal.charCodeAt(i + 9)], 8));
      // fallthrough
      case 9:
        kv2 = x64Xor(kv2, [0, keyVal.charCodeAt(i + 8)]);
        kv2 = x64Multiply(kv2, c2);
        kv2 = x64Rotl(kv2, 33);
        kv2 = x64Multiply(kv2, c1);
        hv2 = x64Xor(hv2, kv2);
      // fallthrough
      case 8:
        kv1 = x64Xor(kv1, x64LeftShift([0, keyVal.charCodeAt(i + 7)], 56));
      // fallthrough
      case 7:
        kv1 = x64Xor(kv1, x64LeftShift([0, keyVal.charCodeAt(i + 6)], 48));
      // fallthrough
      case 6:
        kv1 = x64Xor(kv1, x64LeftShift([0, keyVal.charCodeAt(i + 5)], 40));
      // fallthrough
      case 5:
        kv1 = x64Xor(kv1, x64LeftShift([0, keyVal.charCodeAt(i + 4)], 32));
      // fallthrough
      case 4:
        kv1 = x64Xor(kv1, x64LeftShift([0, keyVal.charCodeAt(i + 3)], 24));
      // fallthrough
      case 3:
        kv1 = x64Xor(kv1, x64LeftShift([0, keyVal.charCodeAt(i + 2)], 16));
      // fallthrough
      case 2:
        kv1 = x64Xor(kv1, x64LeftShift([0, keyVal.charCodeAt(i + 1)], 8));
      // fallthrough
      case 1:
        kv1 = x64Xor(kv1, [0, keyVal.charCodeAt(i)]);
        kv1 = x64Multiply(kv1, c1);
        kv1 = x64Rotl(kv1, 31);
        kv1 = x64Multiply(kv1, c2);
        hv1 = x64Xor(hv1, kv1);
      // fallthrough
      default:
        break;
    }

    return [kv1, kv2, hv1, hv2];
  }

  function x64hash128(keyVal, seed) {
    keyVal = keyVal || "";
    seed = seed || 0;
    var remainder = keyVal.length % 16;
    var bytes = keyVal.length - remainder;
    var hv1 = [0, seed];
    var hv2 = [0, seed];
    var kv1 = [0, 0];
    var kv2 = [0, 0];
    var c1 = [0x87c37b91, 0x114253d5];
    var c2 = [0x4cf5ad43, 0x2745937f];
    for (var i = 0; i < bytes; i = i + 16) {
      kv1 = [
        (keyVal.charCodeAt(i + 4) & 0xff) |
          ((keyVal.charCodeAt(i + 5) & 0xff) << 8) |
          ((keyVal.charCodeAt(i + 6) & 0xff) << 16) |
          ((keyVal.charCodeAt(i + 7) & 0xff) << 24),
        (keyVal.charCodeAt(i) & 0xff) |
          ((keyVal.charCodeAt(i + 1) & 0xff) << 8) |
          ((keyVal.charCodeAt(i + 2) & 0xff) << 16) |
          ((keyVal.charCodeAt(i + 3) & 0xff) << 24),
      ];
      kv2 = [
        (keyVal.charCodeAt(i + 12) & 0xff) |
          ((keyVal.charCodeAt(i + 13) & 0xff) << 8) |
          ((keyVal.charCodeAt(i + 14) & 0xff) << 16) |
          ((keyVal.charCodeAt(i + 15) & 0xff) << 24),
        (keyVal.charCodeAt(i + 8) & 0xff) |
          ((keyVal.charCodeAt(i + 9) & 0xff) << 8) |
          ((keyVal.charCodeAt(i + 10) & 0xff) << 16) |
          ((keyVal.charCodeAt(i + 11) & 0xff) << 24),
      ];
      kv1 = x64Multiply(kv1, c1);
      kv1 = x64Rotl(kv1, 31);
      kv1 = x64Multiply(kv1, c2);
      hv1 = x64Xor(hv1, kv1);
      hv1 = x64Rotl(hv1, 27);
      hv1 = x64Add(hv1, hv2);
      hv1 = x64Add(x64Multiply(hv1, [0, 5]), [0, 0x52dce729]);
      kv2 = x64Multiply(kv2, c2);
      kv2 = x64Rotl(kv2, 33);
      kv2 = x64Multiply(kv2, c1);
      hv2 = x64Xor(hv2, kv2);
      hv2 = x64Rotl(hv2, 31);
      hv2 = x64Add(hv2, hv1);
      hv2 = x64Add(x64Multiply(hv2, [0, 5]), [0, 0x38495ab5]);
    }
    kv1 = [0, 0];
    kv2 = [0, 0];

    var temp = switchRemainder(
      remainder,
      keyVal,
      kv1,
      kv2,
      c1,
      c2,
      hv1,
      hv2,
      i
    );
    kv1 = temp[0];
    kv2 = temp[1];
    hv1 = temp[2];
    hv2 = temp[3];

    hv1 = x64Xor(hv1, [0, keyVal.length]);
    hv2 = x64Xor(hv2, [0, keyVal.length]);
    hv1 = x64Add(hv1, hv2);
    hv2 = x64Add(hv2, hv1);
    hv1 = x64Fmix(hv1);
    hv2 = x64Fmix(hv2);
    hv1 = x64Add(hv1, hv2);
    hv2 = x64Add(hv2, hv1);
    return (
      ("00000000" + (hv1[0] >>> 0).toString(16)).slice(-8) +
      ("00000000" + (hv1[1] >>> 0).toString(16)).slice(-8) +
      ("00000000" + (hv2[0] >>> 0).toString(16)).slice(-8) +
      ("00000000" + (hv2[1] >>> 0).toString(16)).slice(-8)
    );
  }

  function murmur(keyVal) {
    return x64hash128(keyVal, 31);
  }

  function getMathFingerprint() {
    var M = Math; // To reduce the minified code size
    var fb = function () {
      return 0;
    };
    // Native operations
    var acos = M.acos || fb;
    var acosh = M.acosh || fb;
    var asin = M.asin || fb;
    var asinh = M.asinh || fb;
    var atanh = M.atanh || fb;
    var atan = M.atan || fb;
    var sin = M.sin || fb;
    var sinh = M.sinh || fb;
    var cos = M.cos || fb;
    var cosh = M.cosh || fb;
    var tan = M.tan || fb;
    var tanh = M.tanh || fb;
    var exp = M.exp || fb;
    var expm1 = M.expm1 || fb;
    var log1p = M.log1p || fb;

    // Operation polyfills
    var powPI = function (value) {
      return M.pow(M.PI, value);
    };
    var acoshPf = function (value) {
      return M.log(value + M.sqrt(value * value - 1));
    };
    var asinhPf = function (value) {
      return M.log(value + M.sqrt(value * value + 1));
    };
    var atanhPf = function (value) {
      return M.log((1 + value) / (1 - value)) / 2;
    };
    var sinhPf = function (value) {
      return M.exp(value) - 1 / M.exp(value) / 2;
    };
    var coshPf = function (value) {
      return (M.exp(value) + 1 / M.exp(value)) / 2;
    };
    var expm1Pf = function (value) {
      return M.exp(value) - 1;
    };
    var tanhPf = function (value) {
      return (M.exp(2 * value) - 1) / (M.exp(2 * value) + 1);
    };
    var log1pPf = function (value) {
      return M.log(1 + value);
    };

    return {
      acos: acos(0.123124234234234242),
      acosh: acosh(1e308),
      acoshPf: acoshPf(1e154), // 1e308 will not work for polyfill
      asin: asin(0.123124234234234242),
      asinh: asinh(1),
      asinhPf: asinhPf(1),
      atanh: atanh(0.5),
      atanhPf: atanhPf(0.5),
      atan: atan(0.5),
      sin: sin(-1e300),
      sinh: sinh(1),
      sinhPf: sinhPf(1),
      cos: cos(10.000000000123),
      cosh: cosh(1),
      coshPf: coshPf(1),
      tan: tan(-1e300),
      tanh: tanh(1),
      tanhPf: tanhPf(1),
      exp: exp(1),
      expm1: expm1(1),
      expm1Pf: expm1Pf(1),
      log1p: log1p(10),
      log1pPf: log1pPf(10),
      powPI: powPI(-100),
    };
  }

  function getTouchSupport() {
    var n = navigator;

    var maxTouchPoints = 0;
    var touchEvent = false;
    if (n.maxTouchPoints !== undefined) {
      maxTouchPoints = parseInt(n.maxTouchPoints);
    } else if (n.msMaxTouchPoints !== undefined) {
      maxTouchPoints = n.msMaxTouchPoints;
    }
    try {
      document.createEvent("TouchEvent");
      touchEvent = true;
    } catch (err) {
      touchEvent = false;
    }
    var touchStart = false;
    if (window.ontouchstart) {
      touchStart = true;
    }
    return {
      maxTouchPoints: maxTouchPoints,
      touchEvent: touchEvent,
      touchStart: touchStart,
    };
  }

  if (document.readyState === "complete") {
    getFpRelatedDom();
    window["idss_fp"]["fp"].readyState = "complete";
  }
})();
