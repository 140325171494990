export const US = {
  top: 'TOP',
  accountisempty: 'User ID cannot be empty.',
  accountlength: 'User ID cannot exceed 50 characters.',
  systemacccount: 'User ID cannot start with "pub_", "eta_", or "igl_".',
  staffaccount: 'User ID cannot be comprised of 1 to 3 letters and a number.',
  acccountprefix: 'User ID must start with a letter or a digit.',
  accountcharachter: 'Only letters, digits, and special characters (- _ .) are allowed..',
  bindingTitle: 'Link Uniportal',
  bindinginfo:
    'Please link to your existing Uniportal account when ' + 'logging in to this Huawei IT system for the first time.',
  bindingCloudInfo:
    'You have signed in with your Huawei ID and linked to' +
    ' Uniportal account on Huawei Cloud before. Please link to your basic' +
    ' information of your existing Uniportal account.',
  bindinginfo2: 'Choose how to link to your Uniportal account.',
  bindinginfo3: 'Choose how to create your Uniportal account. ',
  bindinginfo4: 'If you choose to use your mobile number, email verification is required.',
  usePhone: 'Mobile Number',
  useEmail: 'Email Address',
  emailAddress: 'Email Address',
  forgetpwd: 'Forgot password? ',
  restPassword: ' Reset password',
  previous: 'Previous',
  next: 'Next',
  bindingAccount: 'Link',
  createAccount: 'Create Uniportal Account',
  createAccountInfo:
    'Please create a Uniportal account and agree to the User Agreement' +
    ' and Privacy Statement before signing in to this Huawei IT system for the first time.',
  account: 'User ID',
  lastName: 'Last Name',
  firstName: 'First Name',
  email: 'Email',
  smsVerification: 'SMS Verification',
  getCode: 'Get Code',
  agreement:
    '<span>I have read and agree to </span><a href="javascript:popu(0);">the User' +
    ' Agreement</a><span> and </span><a  href="javascript:popu(1);"> Privacy Statement</a><span>.</span>',
  moreMobileUserTip: 'More than one account linked. Select one.',
  moreEmailUserTip: 'More than one account linked. Select one.',
  createAndLink: 'Create And Link',
  emailVerification: 'Email Verification Code',
  emailEmptyTip: 'Email cannot be empty.',
  emailFormatTip: 'Invalid email address format.',
  passwordEmptyTip: 'Password cannot be empty.',
  tips: 'Prompt',
  mobileNumber: 'Mobile Number',
  mobileNumberEmptyTip: 'Mobile Number cannot be empty.',
  mobileNumberFormatTip: 'Invalid mobile number format.',
  countryCode: 'Country code cannot be empty.',
  codeImage: 'Code in the Image',
  codeImageEmptyTip: 'Enter the code shown in the image.',
  emailCodeEmptyTip: 'Email Verification Code cannot be empty.',
  smsCodeEmptyTip: 'SMS Verification Code cannot be empty.',
  submit: 'Submit',
  skip: 'Skip',
  resend: 'Resend',
  cancel: 'Cancel',
  ok: 'OK',
  save: 'Save',
  accountTip1: 'User ID must:',
  accountTip2: 'Start with a letter or digit.',
  accountTip3: 'Use letters, digits, underscores (_), dots (.), or hyphens (-) only.',
  accountTip4: 'Example: <b>Jack_Wang</b>',
  lastNameEmptyTip: 'First Name cannot be empty.',
  firstNameEmptyTip: 'Last Name cannot be empty.',
  lastNameCannotLength: 'First Name cannot exceed 50 characters.',
  firstNameCannotLength: 'Last Name cannot exceed 50 characters.',
  accountEmptyTip: 'User ID cannot be empty.',
  lastnameCharater: 'Last Name cannot contain the following characters: ',
  firstnameCharater: 'First Name cannot contain the following characters: ',
  sendCodeTip1: 'The verification code has been sent to {n}',
  sendCodeTip2: 'Close in {n} seconds',
  parTip:
    'Please verify your identity with your mobile number. A verified mobile' +
    ' number can be used to log in to the system, reset or change password.',
  parTip2:
    'Please verify your identity with email address. A verified email' +
    ' address can be used to log in to the system, reset or change passwords.',
  acls: 'Account created and linked successfully.',
  linkedSucc: 'Account linked successfully.',
  mobileLengthTip: 'Mobile Number must contain 6 to 30 characters',
  nametszf: 'The name cannot contain special characters or digits.',
  heesepi: 'Huawei employees and outsourced employees should visit W3 to edit personal information.',
  back: 'Back',
  useCenter: 'User Center',
  setNickName: 'Touch to set a nickname.',
  nickname: 'Nickname',
  nicknameEmpty: 'Not empty.',
  ubi: 'Uniportal Basic Information',
  accountName: 'Account',
  name: 'Name',
  nosetname: 'Not set',
  nosetpwd: 'Not set',
  nosetphone: 'Not set',
  nosetemail: 'Not set',
  verified: 'Verified',
  notVerified: 'Not verified',
  updatePwd: 'Change Password',
  linked: 'Linked',
  setpwd: 'Set Password',
  linkAccount: 'Link Account',
  unlink: 'Unlink Account',
  chtvyi: 'Choose how to verify your identity.',
  SMSVerification: 'SMS Verification',
  SMSVerification2: 'SMS Verification',
  EmailVerification: 'Email Verification',
  mobileNumberVerification: 'Mobile Number Verification',
  emailVer: 'Email Verification',
  verificationSuccess: 'Verification Successfully.',
  unlinkeduccess: 'Unlinked successfully.',
  changeName: 'Change Name',
  addName: 'Add Name',
  changeMobile: 'Change Mobile Number',
  linkMobileNumber: 'Link',
  changeEmailAddress: 'Change Email Address',
  linkEmail: 'Link',
  failed: 'Operation failed',
  success: 'Operation succeed',
  uftay:
    'Unlink from third-party account? After the account is unlinked,' +
    ' you can still sign in with your Uniportal account.',
  password: 'Password',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  confirmPassword: 'Confirm Password',
  iknow: 'OK',
  changedSuccess: 'Changed successfully.',
  changedFailed: 'Changed failed.',
  oldPasswordEmpty: 'Password cannot be empty.',
  newPasswordEmpty: 'New Password cannot be empty.',
  npmcc: 'New Password must contain 8 characters.',
  npcec: 'New Password cannot exceed 60 characters.',
  npcsoewas: 'New Password cannot start or end with a space.',
  npccyui: 'New Password cannot contain your user ID.',
  npmcldsc: 'New Password must contain letters, digits, and special characters.',
  cpcbe: 'Confirm Password cannot be empty.',
  cpmbtsanp: 'Confirm Password must be the same as New Password.',
  verification: 'Verification',
  backTip: 'Sign in <span style="color: #007DFF;">{n}</span>second later.',
  backLogin: 'Back to Login',
  tuhnl: 'The user has not login!',
  weChat: 'WeChat',
  linkedin: 'LinkedIn',
  useUserids: 'Your account has been used by another person. Please enter another account.',
  passwordTip1: 'Contain at least 8 characters',
  passwordTip2: 'Not contain user ID.',
  passwordTip3: 'Contain letters, digits and special characters.',
  contactUs: 'Contact Us',
  help: 'Help',
  chtlarr: 'Copyright © Huawei Technologies Co., Ltd. 1998-2020. All rights reserved.',
  ppArgee: 'Privacy Policy',
  loginOut: 'Login Out',
  uAccountPassword: 'Uniportal Account Password',
  selectAccount: 'Select an account',
  cypmg: 'Change your password and mobile phone number for your W3 account on ME.',
  lemn: 'Link to an email or a mobile number first.',
  lemn2: 'Link to an email or a mobile number first.',
  mobileNumberRegister: 'The mobile number has been registered.',
  emailRegister: 'The email has been registered.',
  agreementTip: 'Select the User Agreement and Privacy Statement.',
  informTip:
    'Dear user, as this is the first time you have logged in to the Huawei' +
    ' IT application, please complete the following user information.',
  compUserInfor: 'Complete User Information',
  create: 'Submit',
  createUserSuccess: 'The user information is successfully modified.',
  w3Account: 'Huawei internal employee W3 account',
  w3AccountLogin: 'W3 account login',
  login: 'Login',
  hwAccountLogin: 'Huawei account login',
  accountIsEmpty: 'Account is empty',
  accountTip: 'The length of the account number cannot be greater than 100 bytes, please re-fill',
  pwdIsEmpty: 'Password is empty',
  pwdTip: 'Password length must be between 8 and 60 characters',
  accountErrpr: 'The account or password is wrong, you can also try',
  times: 'times',
  Seconds: 'Seconds',
  errorTimes: 'Account locked due to too many incorrect account or password',
  otherLogin: 'Other login methods',
  directions:
    'We provide you with a HUAWEI ID service, and use your account and' +
    ' network information during the login process to improve the login experience.',
  underMore: 'Understand more >',
  directions1:
    'Sorry, the page cannot be displayed properly due to the ' +
    ' abnormal loading of HUAWEI ID components. You can refresh and try again later, or click',
  directions2: 'Enter the original Uniportal login authentication page.',
  links: 'links',
  legalNotices: 'Legal Notices',
  hwPrivacy: 'Statement on HUAWEI ID and Privacy',
  loginWithHuaweiId: 'Login with HUAWEI ID',
  pravacyText1:
    'Log in to your HUAWEI ID to access and use HUAWEI apps/services, ' +
    'HUAWEI websites, or other non-HUAWEI services that allow HUAWEI ID to log in. ' +
    'In order to improve the login experience, we will log in to the same operating ' +
    'company or affiliated companies that have signed data ' +
    'sharing and confidentiality agreements. Account users, provide a unified login method.',
  pravacyText2:
    'When you log in, we will route to different operating companies ' +
    'to provide you with services based on your IP address. If the access is routed to mainland China, ' +
    'the Huawei account operated by Huawei Software Technology Co., Ltd. provides services for you (see ',
  pravacyText3:
    '); If the route is not located in mainland China, ' +
    'the HUAWEI ID operated by Huawei Services (Hong Kong) Co., Ltd. will serve you (see ',
  pravacyText4:
    'If you are a HUAWEI ID user, your HUAWEI ID will provide you with login services. ' +
    'If not, we will authorize you to transfer the user name and ' +
    ' password you entered to the service that provides you with' +
    ' account login. HUAWEI ID does not save the username and password you enter.',
  forgetPwd: 'Forgot password',
  modifyPwd: 'Modify password',
  appNotReg: 'App not registered',
  loggingOut: 'logging out...',
  loginLoading: 'logging in...',
  copyright: 'Copyright © Huawei Technologies Co., Ltd. 1998-2022. All rights reserved.',
  useCenter: 'Use Center',
  changePassword: 'Change Password',
  login: 'Login',
  ppArgee: 'Privacy Policy',
  US: 'English (United States)',
  'CAS-03-00001': 'The ticket grant ticket not found.',
  'CAS-03-00002': 'The ticket grant ticket has expired.',
  'CAS-03-00003': 'The service ticket not found.',
  'CAS-03-00004': 'The serice ticket has redeemed.',
  'CAS-03-00006': 'The service not found.',
  'CAS-03-00007': 'The service invalid.',
  'CAS-01-00004': 'Unauthorized access.',
  'CAS-00-00001': 'Internal Server Error.',
  'CAS-07-00010': 'The verification code cannot be sent repeatedly within 60 seconds.',
  'CAS-07-00009': 'You have sent SMS verification codes too frequently. Please try again 24 hours later.',
  'CAS-02-00041': 'The email address or phone number is disabled',
  'CAS-02-00043': 'The country code has been disabled',
  'CAS-02-00044': 'Invalid mobile number format.',
  'CAS-07-00011': 'The mfa login information has expired for more than 10 minutes.',
  'CAS-07-00012': 'The number of verification codes exceeds the maximum.',
  'CAS-07-00014': 'The account registration area is empty.',
  'CAS-07-00015': 'The email verification code is empty.',
  'CAS-08-00006': 'Send mfa message failed.',
  'CAS-07-00031': 'You have sent SMS verification codes too many times. Please try again one hour later.',
  'CAS-07-00032':
    'The number of times you send the SMS verification code is too high. Please try again 5 minutes later.',
  'CAS-07-00033': 'You have sent verification codes too many times. Please try again 5 minutes later.',
  'CAS-07-00034': 'You have sent verification codes too frequently. Please try again one hour later.',
  'CAS-07-00035': 'You have sent verification codes too many times. Please try again 24 hours later.',
  loading: 'loading...',
  warmTips:
    'Reminder: Only Huawei internal employees can log in with W3 accounts. For external users, please switch to',
  hwAccount: 'HUAWEI ID',
  pwdAccountTips: 'Enter the account name/email address/mobile number/W3 account whose password needs to be changed.',
  pwdAccountForgetTips: 
    'Forgot your password? You can retrieve it through your account name, email address, mobile number, or W3 account',
  pwdW3Account: 'Please enter a W3 account.',
  pleaseEnterPassword: 'Please enter the password.',
  backLoginTips: 'Return to landing page',
  pwdCodeTips:
    'To confirm that the operation is performed by yourself, ' +
    'select an authentication mode to complete the authentication.',
  pwdCodePhoneTips: 'The verification code will be sent to the mobile number:',
  pwdCodeEmailTips: 'The verification code will be sent to:',
  pwdCodeChangePhone: 'Selecting a mobile phone verification method',
  pwdCodeChangeEmail: 'Select Email Authentication Mode',
  confirmNewPassword: 'Confirm New Password',
  resetHWPassword: 'Change Domain Password',
  pwdSuccessTips:
    'Click Modify Domain Password to change your domain password at the same time. ' +
    'If you cannot log in to Windows using the new password, try using your old password.',
  warmTips: 'Warm Tips',
  endTime:
    'Your password will expire in <span style="color: #F66F6A;">{time}</span> days. ' +
    'Change the password in time to prevent your account from being affected.',
  modifyNow: 'Modify Now',
  doNotmodifyNow: 'Do not modify this parameter.',
  expirationTips1:
    'Your password has expired. To ensure your account security, ' +
    'change the password immediately.',
  expirationTips2: 'If you do not change the password immediately, you will not be able to access the website.',
  warningTips1:
    'If your W3 account does not have a mobile number or email address, ' +
    'the system cannot reset or change the password. You can ask a colleague to help you retrieve your password.',
  warningTips2: 'Link to Help Colleagues Retrieving Passwords:',
  updateSuccess: 'Reset succeeded.',
  againCode: 'Re-obtain {time}s',
  hwAccountSuccessTips: 'Your domain password changed successfully.',
  hwAccountSuccessTime:
    'Your domain password page will return to login in <span style="color: #238FFF;">{time}</span> seconds!',
  hwAccountErrorTips: 'System error. Failed to change your domain password.',
  hwAccountInvalid: 'Your phone has not been registered or is not enabled for login. Click Register.',
  accountNotExist: 'The account does not exist.',
  passwordRules1: 'To ensure your account security, reset the password according to the following rules:',
  passwordRules2: '1. The password must contain at least eight but not more than 60 characters.',
  passwordRules3: '2. The password must contain letters, digits, and special characters.',
  passwordRules4: '3. The password cannot contain your account name, email address, or mobile number.',
  passwordRules5: '4. The last two passwords cannot be used.',
  oldPasswordError: 'The old password is incorrect.',
  newPasswordError:
    'The new password does not meet the requirements. ' +
    '(The new password cannot be the same as the recent historical password.)',
  verifyCodeError: 'The verification code is incorrect or has expired.',
  timeOut: 'The verification code cannot be sent repeatedly within 60 seconds.',
  longTime: 'The page stays for a long time. Please change or reset the password.',
  resetPassword: 'Reset Password',
  newPwdErrorTips: 'The new password cannot contain an email address, phone number, or account number.',
  systemError: 'Internal system error. Please try again later.',
  serviceNotFound: 'The service not found.',
  checkUserInfo: 'Need to verify your identity',
  checkUserWarnTips: 'Your current operation is risky. To ensure account security, enter the verification code.',
  checkUserSecond:
    'Secondary authentication is enabled for the app you are accessing. ' +
    'For account security, enter the verification code.',
  sendCodeTitle: 'The verification code will be sent to:',
  morePhoneTips:
    'Your account is bound to multiple mobile numbers. Select a mobile number to receive verification SMS messages.',
  moreEmailTips:
    'Your account is bound to multiple email addresses. ' +
    'Please select the email address for receiving verification emails.',
  cerCodeErrorTips: 'The verification code is incorrect or has expired.',
  certificationTitle: 'Certification',
  loginRefused: 'Login is not allowed.',
  sendSmsTips1: 'SMS verification code sent to ',
  sendSmsTips2: ' , effective in 10 minutes!',
  sendEmailTips1: 'Email verification code sent to ',
  uniportalAccountLogin: 'Sign In with Uniportal ID',
  w3AccountTips: 'W3 Account',
  registration: 'Register',
  helpTips: 'Click to get more help?',
  passwordEnd: 'Password Expired',
  passwordExpiring: 'Password is about to expire',
  setAccountPlaceholder: 'Account name/email address/mobile number/W3 account',
  accountPleaseSelect: 'Please select',
  resetPwMultiAccountPhone: 
    'The mobile number is associated with multiple accounts. Select an account to retrieve the password.',
  resetPwMultiAccountEmail: 
    'The email address is associated with multiple accounts. Select an account to retrieve the password.',
  ressetPwMultiAccount: 'Please select the account whose password you want to change.',
  closeButton: 'Close',
  uniportalAccount: 'Uniportal account name',
  uniportalGives:
    'Your Uniportal account gives you access to Huawei official website and Huawei ' +
    'carrier technical support services.',
  UniportalA: 'Reset Uniportal account password',
  rpw: 'Reset password',
  hwAccount: 'HUAWEI ID',
  hwGives:
    "Your HUAWEI ID gives you access to all of Huawei's services, such as HUAWEI Mobile Cloud," +
    ' Huawei Store, AppGallery, HUAWEI Developers, and more. You can use it to log in on' +
    ' Huawei phones, wearables, and other devices as well.',
  hwA: 'Regain access to your HUAWEI ID',
  restrictedAccess: 'Restricted access',
  permissionError: 'Sorry, you do not have permission to access the current page.',
  changeAccount: 'Switching an Account',
  changeAccountTips: 'To continue, please click here',
  pleaseSelect: 'Please Select',
  accountLoginBtn: 'Account and password login',
  smsLoginBtn: 'SMS verification code login',
  moreAccountErrorTips: 
    'The mobile number has been associated with multiple accounts. ' + 
    'You cannot log in to the system by SMS. Use another method to log in to the system.',
  moreAccountTips: 
    'Multiple accounts have been registered with your mobile phone or email address. ' + 
    'Please select an account.',
  uidHasExpired: 'The UID has expired.',
  serviceEmptyTips: 'The service parameter cannot be empty.',
  pwdHasExpiredTips: 'Your password has been initialized or has expired. Please change it now before you proceed.',
  pwdwillExpiredTips: 'Your password will expire in',
  pwdwillExpiredDayTips: 'days. Please change it in time.',
  phoneEmailRejectLogin: 'Login by w3 phone or email is rejected.',
  englishVersion: 'English',
  appHelp: 'help',
  loginIssues: 'We are having trouble logging in?',
  forgettingPassword: 'I forgot the password.',
  accountLogin: 'Account login',
  errorUserAndPsw: "Incorrect user name or password. Enter another incorrect user name or password.",
  lockFiveMinutes: "times will be locked.",
  forgetPsw: "Forgot Password?",
  resetNewPsw: "Reset a new password",
  beyondFailTimes: "The number of login failures has exceeded the limit. Please",
  retry: "Retry in seconds!",
  moreEnterPasswords: "Frequent password entry is cumbersome?",
  passwordfreeLogin: "I want to turn on (off) password-free login",
  pleaseVisit: "Please visit",
  myAccount: "My Account",
  noW3Acount: "I don't have a W3 account.",
  moreAccount: "More account and password questions",
  resetYourPassword:
    "Enter your account number in the text box and click Submit. "
    + "You will receive an activation email. Click the link in the email to reset your password.",
  huaweiAccountRules:
    "A Huawei employee will be assigned a W3 account with initials and employee ID. For informal employees,"
    + " contact the Huawei contact person to fill in the information.",
  eflow: "e-flow",
  applyW3Account: "Apply for a W3 account. After the application is approved, you can create a W3 account.",
  verificationCode: "Failed to receive the verification code?",
  codeRightTip: "The verification code has been sent. There may be a delay. Please wait or obtain another one.",
  errorCAS0200041: 'The email address or phone number is disabled',
  errorCAS0200043: 'The country code has been disabled',
  smsSendTo: 'SMS verification code sent to',
  emailSendTo: 'Email verification code sent to',
  tenMinutes: ', valid within 10 minutes',
  freeLogin: 'How do I enable password-free login?',
  freeLoginFailed: 'Password-free login failed.',
  serviceConsulting: 'Service consultation:',
  error403Title: '403',
  error403Content: 'The resource you requested cannot be accessed',
}
