<template>
  <div
    class="popup_menu"
    :class="visible?'active': ''"
  >
    <div class="m_header">
      <img :src="logoMobile" alt="" class="m_header_logo" v-if="isUniportal">
      <span class="logo" v-else></span>
      <span
        class="close_icon"
        @click="hide"
      ></span>
    </div>
    <div class="menu_wrap">
      <div
        class="menu_item"
        @click="langVisible = !langVisible"
      >
        {{currentLang}}
        <span
          class="pull_down_icon"
          id="pull_down_icon"
        ></span>
      </div>
      <ul
        class="language_wrap"
        :class="langVisible ? 'active' : ''"
        ref="languageWrap"
      >
        <li
          v-for="item in options"
          class="language_item"
          :key="item.value"
          @click="handelChange(item.value)"
        >{{item.label}}</li>
      </ul>
      <div
        class="menu_item"
        v-if="isLogin"
      >
        <a href="mailto:ithotline@huawei.com">{{$t('contactUs')}}</a>
      </div>
      <div
        class="menu_item"
        v-if="isLogin"
      >
        <a :href="helpLink">{{$t('help')}}</a>
      </div>
      <div
        class="menu_item"
        @click="popu(1)"
      >
        {{$t('ppArgee')}}
      </div>
      <div
        class="menu_item"
        @click="loginOut"
        v-if="isLogin"
      >
        {{$t('loginOut')}}
      </div>
    </div>
    <ul>
    </ul>
  </div>
</template>
<script>
import { loginOut, links, changeLang, helpSwitch } from '@/api'
import { getQueryValue, getQueryVal, getLanguageList } from '@/utils/utils'
import { agreementUrl } from '@/assets/js'
export default {
  data() {
    return {
      links,
      visible: false,
      langVisible: false,
      helpLink: this.$i18n.locale === 'zh_CN' ? 'getMobileHelpEn' : 'links.getMobileHelpEn',
      currentLang: '',
      options: []
    }
  },
  props: {
    title: {
      type: String
    },
    logoMobile: {
      type: String,
      default: ''
    }
  },
  computed: {
    isLogin() {
      return false
    },
    isUniportal() {
      return this.$route.name == 'uniportalAccount'
    },
  },
  created() {
    const data = getLanguageList()
    data.map((item, i) => {
      const options = {}
      for (const i in item) {
        options.value = i
        options.label = item[i]
        if (i === this.$i18n.locale) {
          this.currentLang = item[i]
        }
      }
      this.options.push(options)
    })
  },
  methods: {
    loginOut() {
      loginOut()
    },
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },
    popu(type) {
      const locale = this.$i18n.locale
      const path = window.location.href
      if (path.indexOf('#/account') > 0) {
        window.open(
          agreementUrl + '?language=' + locale +
          '&code=CN&version=20200901&branchid=0&isNewCAS=1&themeName=&anchorType='
        )
      } else {
        this.hide()
        let query = { service: getQueryValue('service', true), tenantId: getQueryVal('tenantId') };
        this.$router.push({
          path: '/privacyPolicy', query
        })
      }

    },
    handelChange(value) {
      document.cookie = 'lang = ' + value
      location.reload()
    }
  }
}
</script>
<style lang="scss" scoped>
.popup_menu {
  display: none;
}
@media screen and (max-width: 767px) {
  .logo {
    width: 100px;
    background-image: url('../assets/img/hw_logo.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
  .pull_down_icon {
    float: right;
    transform: rotate(-90deg);
    margin: 6px;
    transition: transform 0.3s;
  }
  .pull_down_icon::before {
    content: '';
    display: block;
    height: 1px;
    background: #000;
    width: 10px;
    transform: rotate(45deg);
    transform-origin: left center;
  }
  .pull_down_icon::after {
    content: '';
    display: block;
    height: 1px;
    background: #000;
    width: 10px;
    transform: rotate(-45deg);
    transform-origin: left center;
  }
  .close_icon {
    margin-top: -4px;
  }
  .close_icon::before {
    content: '\2716';
    font-size: 20px;
  }
  .popup_menu {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 999;
    transform: translateX(100%);
    transition: transform 0.3s;
  }
  .popup_menu.active {
    transform: translateX(0);
  }
  .m_header {
    display: flex;
    font-weight: bold;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 8px 25px 12px 16px;
    border-bottom: 1px solid #d8d8d8;
  }
  .menu_wrap {
    padding: 0 24px;
  }
  .menu_item {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    font-size: 16px;
    color: #666;
    border-bottom: 1px solid #e0e0e0;
    a {
      color: #666;
    }
  }
  .language_wrap {
    display: none;
    padding-left: 0;
    margin: 12px 0;
    max-height: 400px;
    overflow: auto;
  }
  .language_wrap.active {
    display: block;
  }
  .language_item {
    line-height: 42px;
    color: #000;
    padding-left: 12px;
    font-size: 16px;
    list-style: none;
  }
}
.m_header_logo {
  width: auto;
  height: 40px;
}
</style>
