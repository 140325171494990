<template>
  <!--页尾-->
  <div class="pub-footer">
    <div class="pub-footer-left">
      <span v-text="footerHW"></span>
      <span>
        <p
          class="footer-a2"
          v-html="footerHWA2"
          style="line-height:36px;"
        ></p>
      </span>
    </div>
    <div class="pub-footer-right">
      <span v-if="flag">
        <a
          href="javascript:void(0)"
          @click="forwardLegaly()"
          target="_self"
          class="footer-a2"
          v-text="footerContactUs"
        ></a>
        <span>|</span>
      </span>
      <span>
        <a
          href="javascript:void(0)"
          @click="forwardPrivacy()"
          target="_self"
          class="footer-a2"
          v-text="footerPrivacyPolicy"
        ></a>
      </span>
    </div>
  </div>
</template>
<script>
import { detailAgreementUrl } from '@/assets/js'
export default {
  components: {},
  data() {
    return {
      footerContactUs: this.$t('contactUs'),
      footerPrivacyPolicy: this.$t('ppArgee'),
      footerHW: '',
      footerHWPhone: this.$t('chtlarr'),
      msg: 'Welcome to Your Vue.js App',
      flag: false,
      locale: ''
    }
  },
  props: {
    footerHWA2: {
      type: String,
      default: ''
    },
  },
  created() {
    this.locale = this.$i18n.locale
  },
  methods: {
    forwardLegaly() {
      return null
    },
    forwardLegal() {
      return null
    },
    forwardPrivacy() {
      window.open(
        detailAgreementUrl + '?language=' + this.locale + '&code=CN&branchid=1&themeName='
      )
    }
  }
}
</script>
<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  .pub-footer {
    background: #3e3f4a;
    height: 36px;
    line-height: 36px;
    font-size: 12px;
    color: #afb1b6;
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  .pub-footer-left {
    float: left;
    font-family: Helvetica;
    margin-left: 10%;
    color: #afb1b6;
    line-height: initial;
  }
  .pub-footer-left span a {
    color: #afb2b8;
    text-decoration: none;
    margin-left: 18px;
  }
  .pub-footer-right span a {
    color: #afb2b8;
    text-decoration: none;
  }
  .pub-footer-right {
    float: right;
    margin-right: 10%;
    span:nth-child(even) {
      display: inline-block;
      margin: 0 10px;
      height: 11px;
    }
  }
}
@media screen and (max-width: 767px) {
  // 移动端
  .pub-footer {
    display: none;
  }
}
</style>
