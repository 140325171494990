import axios from 'axios' // 引入axios
import { Modal } from '@aurora/vue'
import { getQueryValue, getQueryVal, getQueryParam4backend } from '@/utils/utils'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  timeout: 50000 // 超时间
})

instance.interceptors.request.use(
  (config) => {
    if (config.method === 'post') {
      config.data['tenantPublicId'] = getQueryVal('tenantId') ||
        getQueryParam4backend('amp;tenantId') || getQueryParam4backend('tenantId')
      config.data['service'] != undefined ? '' : (config.data['service'] = getQueryValue('service'))
    } else {
      config.params['tenantPublicId'] = getQueryVal('tenantId') ||
        getQueryParam4backend('amp;tenantId') || getQueryParam4backend('tenantId')
      config.params['service'] != undefined ? '' : (config.params['service'] = getQueryValue('service'))
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)
// 请求拦截 请求拦截的成功和失败的回调函数

instance.interceptors.response.use(
  (res) => {
    return res
  },
  (err) => {
    if (err.response.data.errorCode === 'CAS-00-00005') {
      location.href = `${location.origin}${location.pathname}#/403`
    }
    return Promise.resolve(err.response)
  }
)
// 相应拦截的成功和失败

export default instance
